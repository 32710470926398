import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { SearchIcon } from '@heroicons/react/outline';

import { getPatients, clearPatientView } from '../../../../store/patients';

import Dashboard from '../../../layout/dashboard';
import Loading from '../../../Loading';

export const mapStateToProps = ({ patients }) => ({
  patients,
});

export const mapDispatchToProps = dispatch => ({
  getPatients: () => dispatch(getPatients()),
  clearPatientView: () => dispatch(clearPatientView()),
});

const deviceStatusCss = {
  'live': 'bg-green-100 text-green-800',
  'next': 'bg-yellow-100 text-yellow-800',
}

function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

function TableHeaderCell({ children, ...props }) {
  return (
    <th
      scope="col"
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      {...props}
    >
      {children}
    </th>
  );
}

const PatientRow = React.memo(({ patient, deviceStatusCss }) => (
  <tr key={patient.playphysio_id} className="bg-white">
    <td className="px-6 py-4 whitespace-nowrap">
      <div>
        <div className="text-sm font-medium text-gray-900">
          {patient.name}
        </div>
        <div className="text-sm text-gray-500">
          {patient.secret_word}
        </div>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
      {patient.date_of_birth}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
      {patient.hospital_id}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
      {patient.questionnaire_name !== '' ? (
        <span className={`px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize`}>
          <div className="flex">{patient.questionnaire_name || 'Unknown'}<svg xmlns="http://www.w3.org/2000/svg" className="text-red-800 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg></div>
        </span>
      ) : (
        <span className={`px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize`}>
          <div className="flex">None<svg xmlns="http://www.w3.org/2000/svg" className="text-green-800 ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg></div>
        </span>
      )}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span className={`px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize ${deviceStatusCss[patient.device_status] || 'bg-blue-100 text-blue-800'}`}>
        {patient.device_status}
      </span>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium sm:px-6 lg:px-8">
      <Link to={`/patients/${patient.playphysio_id}/overview`} className="text-indigo-600 hover:text-indigo-900">
        <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
          View
        </button>
      </Link>
    </td>
  </tr>
));

function Patients(props) {
  useTraceUpdate(props);
  const [searchTerm, setSearchTerm] = useState(null);
  const { patients, getPatients, clearPatientView } = props;
  const fetchData = useCallback(() => {
    getPatients();
  }, [getPatients]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);


  console.log(props.patients)

  const filteredPatients = useMemo(() => {
    return patients.list.items.filter(patient => {
      if (searchTerm === null) {
        return patient;
      }
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        (patient.name && patient.name.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (patient.secret_word && patient.secret_word.toLowerCase().includes(lowerCaseSearchTerm))
      );
    }).sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
    });
  }, [patients.list.items, searchTerm]);

  const rows = useMemo(() => {
    return filteredPatients.map(patient => (
      <PatientRow key={patient.playphysio_id} patient={patient} deviceStatusCss={deviceStatusCss} />
    ));
  }, [filteredPatients, deviceStatusCss]);

  console.log('eeeh')

  return (
    <Dashboard
      title="Patients"
      buttons={
        <Link onClick={clearPatientView} to="/physiopal/find">
          <button
            type="button"
            className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-1 sm:ml-3"
          >
            New Patient
          </button>
        </Link>
      }
    >
      <div className="flex-1 flex flex-col">
        <div className="border-b border-gray-200 bg-white">
          <label htmlFor="search_field" className="sr-only">
            Search patients
          </label>
          <div className="px-4 relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5" />
            </div>
            <input
              onChange={(event) => setSearchTerm(event.target.value)}
              id="search_field"
              className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
              placeholder="Search patients"
              type="search"
              name="search"
            />
          </div>
        </div>
        {patients.list.loading ? (
          <div className="p-4">
            <Loading />
          </div>
        ) : (
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Date of birth</TableHeaderCell>
                        <TableHeaderCell>Hospital ID</TableHeaderCell>
                        <TableHeaderCell>Survey due</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell className="relative px-6 py-3">
                          <span className="sr-only">View</span>
                        </TableHeaderCell>
                      </tr>
                    </thead>
                    <tbody data-testid="patient-list-body">
                      {rows}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
