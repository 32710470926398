import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  getTeamRequest,
  getTeamFailure,
  getTeamSuccess,
  getTeamMemberRequest,
  getTeamMemberSuccess,
  deleteTeamMemberRequest,
  deleteTeamMemberFailure,
  createTeamUserRequest,
  createTeamUserFailure,
  createTeamUserSuccess
} from '../store/team';

import { getTeam, getTeamMember, deleteTeamMember, createTeamUser } from '../api/team';

const getToken = state => state.account.token;

function* createTeamUserSaga(action) {
  const token = yield select(getToken);
  const response = yield call(createTeamUser, {
    token,
    ...action.payload,
  });
  yield put(createTeamUserSuccess(response));
  yield put(push('/team'));
}

function* getTeamSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getTeam, {
    token,
  });
  yield put(getTeamSuccess(response));
}

function* getTeamMemberSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getTeamMember, {
    token,
    id: action.payload
  });
  yield put(getTeamMemberSuccess(response));
}

function* deleteTeamMemberSaga(action) {
  const token = yield select(getToken);
  const [response, json] = yield call(deleteTeamMember, {
    token,
    id: action.payload
  });
  if (response.status === 200) {
    yield put(push('/team'));
  } else {
    yield put(deleteTeamMemberFailure(json));
  }
}

export default function* teamSaga() {
  yield takeEvery(getTeamRequest.type, getTeamSaga);
  yield takeEvery(getTeamMemberRequest.type, getTeamMemberSaga);
  yield takeEvery(createTeamUserRequest.type, createTeamUserSaga);
  yield takeEvery(deleteTeamMemberRequest.type, deleteTeamMemberSaga);
};
