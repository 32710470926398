import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Transition } from '@headlessui/react'
import { DocumentDownloadIcon } from '@heroicons/react/solid'
import { Link, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import Dashboard from '../../../layout/dashboard';

import { getReportRequest, setFromDate, setToDate, resetDates } from '../../../../store/reporting';

import {
  getHumanReadableDate,
  getUnixTimestamp,
  getMissingBlows,
  getTotalBlowAttempts,
  getQuality,
  getPlayphysioScore
} from '../../../../utils/adherence';

const mapStateToProps = (state) => ({
  patients: state.patients.list,
  patient: state.patients.view,
  reporting: state.reporting,
  from: state.reporting.from,
  to: state.reporting.to,
});

const mapDispatchToProps = (dispatch) => ({
  getReport: (playphysio_id, from, to) => dispatch(getReportRequest({
    playphysio_id,
    from: from,
    to: to,
  })),
  setFrom: (date) => dispatch(setFromDate(date)),
  setTo: (date) => dispatch(setToDate(date)),
  reset: () => dispatch(resetDates()),
});

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Adherence(props) {
  const { patients, patient, getReport, reporting, setFrom, setTo, reset, from, to } = props;
  const { id } = useParams();

  useEffect(() => {
    getReport(id, from, to);
  }, [patients, patient, getReport, from, to]);

  let data = [];

  for (const report of reporting.data) {
    if (report) {
      data = [
        ...data,
        ...report.sessions || [],
        ...report.missed_sessions || [],
        ...report.required_today || [],
      ].sort((a, b) => a.datetime < b.datetime ? 1 : -1).sort((a, b) => a.playphysio_id < b.playphysio_id);
    }
  }

  const csv_data = [
    ["participant_id", "secret_animal", "session_date", "session_start", "session_end", "prescribed_blows", "completed_blows", "incomplete_blows", "missing_blows", "total_blow_attempts", "playphysio_quality", "playphysio_score"]
  ]
  return (
    <Dashboard title="Adherence"
      buttons={
        <>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div className="hidden md:visible md:inline-flex space-x-6">
                  <div className="space-x-4">
                    <label htmlFor="from">From</label>
                    <input value={from} onChange={(event) => setFrom(event.target.value)} max={to} type="date" name="from" />
                  </div>
                  <div className="space-x-4">
                    <label htmlFor="to">To</label>
                    <input value={to} onChange={(event) => setTo(event.target.value)} min={from} max={new Date().toISOString().split("T")[0]} type="date" name="to" />
                  </div>
                  <button onClick={() => reset()} type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-playphysio focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Reset
                  </button>
                  <CSVLink className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-playphysio focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" data={csv_data} filename={`${patient.playphysio_id}-${patient.secret_word}-${getHumanReadableDate()}-${getUnixTimestamp()}-treatment-export.csv`}>
                    Download CSV
                    <DocumentDownloadIcon
                      className="ml-2 h-5 w-5 text-white group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </CSVLink>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <DocumentDownloadIcon
                              className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            Download as CSV
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </>
      }
    >
      <div className="flex flex-col">
        {id !== 'all' && (
          <div className="p-4">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
                <li className="flex">
                  <div className="flex items-center">
                    <Link to="/patients" className="text-gray-400 hover:text-gray-500">
                      <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                      <span className="sr-only">Patients</span>
                    </Link>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <Link to={`/patients/${patient.playphysio_id}/overview`} aria-current="page" className="ml-4 text-sm font-medium text-gray-500">{patient.name}</Link>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <p className="ml-4 text-sm font-medium text-gray-500">Adherence</p>
                  </div>
                </li>
              </ol>
            </nav>
          </div>
        )}
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Participant ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Session date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Session start
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Session end
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Prescribed blows
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Completed blows
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Incomplete blows
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Missing blows
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total blow attempts
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Quality
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Playphysio score
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Adherence
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reporting.loading ? (
                    <tr>
                      <td>
                        <svg className="animate-spin m-5 h-8 w-8 text-playphysio" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </td>
                    </tr>
                  ) : (
                    data.map((item, itemIdx) => {
                      csv_data.push([
                        item.playphysio_id,
                        item.secret_animal,
                        new Date(item.datetime * 1000).toLocaleDateString(),
                        !item.missed ? new Date(item.datetime * 1000).toLocaleTimeString() : '',
                        !item.missed ? new Date(item.session_end * 1000).toLocaleTimeString() : '',
                        item.total_required_blows,
                        item.total_complete_blows,
                        item.total_incomplete_blows,
                        getMissingBlows(item),
                        getTotalBlowAttempts(item),
                        getQuality(item),
                        getPlayphysioScore(item)
                      ])
                      return (
                        <tr key={itemIdx} className={itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.playphysio_id}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{new Date(item.datetime * 1000).toLocaleDateString()}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{!item.missed ? new Date(item.datetime * 1000).toLocaleTimeString() : '-'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{!item.missed ? new Date(item.session_end * 1000).toLocaleTimeString() : '-'}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.total_required_blows}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.total_complete_blows}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.total_incomplete_blows}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{getMissingBlows(item)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{getTotalBlowAttempts(item)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{getQuality(item)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{getPlayphysioScore(item)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{Math.round(Math.min((item.total_complete_blows / Math.max(item.total_required_blows, 1)) * 100, 100))}%</td>
                        </tr>
                      )
                    }))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Adherence);
