export async function getSubscription(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subscription`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  const json = await response.json();
  return json;
};

export async function updateSubscription(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subscription`, {
    method: 'PUT',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
