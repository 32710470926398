import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getPatientRequest } from '../../../store/patients';

const mapStateToProps = state => ({
  patient: state.patients.view,
});

const mapDispatchToProps = dispatch => ({
  getPatient: (playphysio_id) => dispatch(getPatientRequest(playphysio_id)),
});

function DevicesCard(props) {
  const { getPatient, patient } = props;
  const { id } = useParams();
  useEffect(() => {
    getPatient(id);
  }, [])
  return (
    <div data-testid="practice-card" className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6 flex">
        <div className="flex-1">
          Practice Blows
        </div>
        <div className="flex flex-shrink content-center items-center">
          <span className={`mr-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${patient.practice ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
            {patient.practice ? 'Practice mode on' : 'Practice mode off'}
          </span>
          <Link to={`/patients/${id}/practice`} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesCard);
