import { createAction } from '@reduxjs/toolkit';

export const getLatestTherapyDeviceRequest = createAction('GET_LATEST_THERAPY_DEVICE_REQUEST');
export const getLatestTherapyDeviceFailure = createAction('GET_LATEST_THERAPY_DEVICE_FAILURE');
export const getLatestTherapyDeviceSuccess = createAction('GET_LATEST_THERAPY_DEVICE_SUCCESS');

export const findPhysiopalDeviceRequest = createAction('FIND_PHYSIOPAL_DEVICE_REQUEST');
export const findPhysiopalDeviceFailure = createAction('FIND_PHYSIOPAL_DEVICE_FAILURE');
export const findPhysiopalDeviceSuccess = createAction('FIND_PHYSIOPAL_DEVICE_SUCCESS');
export const findPhysiopalDeviceClear = createAction('FIND_PHYSIOPAL_DEVICE_CLEAR');
export const findPhysiopalDeviceLink = createAction('FIND_PHYSIOPAL_DEVICE_LINK');

export const assignPhysiopalToPatientRequest = createAction('ASSIGN_PHYSIOPAL_TO_PATIENT_REQUEST');
export const assignPhysiopalToPatientFailure = createAction('ASSIGN_PHYSIOPAL_TO_PATIENT_FAILURE');

const initialState = {
  therapy_device: {
    loading: false,
  },
  physiopalDevice: {
    find: {
      loading: false,
      error: null,
    },
    link: {
      loading: false,
      error: null,
    }
  },
};

export default function devices(state = initialState, action) {
  switch (action.type) {
    case getLatestTherapyDeviceRequest.type:
      return {
        ...state,
        therapy_device: {
          loading: true,
        },
      };
    case getLatestTherapyDeviceFailure.type:
      return {
        ...state,
        therapy_device: {
          loading: false,
        },
      };
    case getLatestTherapyDeviceSuccess.type:
      return {
        ...state,
        therapy_device: {
          loading: false,
        },
      };
    case findPhysiopalDeviceRequest.type:
      return {
        ...state,
        physiopalDevice: {
          find: {
            error: null,
            loading: true,
          },
        },
      };
    case findPhysiopalDeviceFailure.type:
      return {
        ...state,
        physiopalDevice: {
          find: {
            error: true,
            loading: false,
          },
        },
      };
    case findPhysiopalDeviceSuccess.type:
      return {
        ...state,
        physiopalDevice: {
          find: {
            error: false,
            loading: false,
            ...action.payload,
          },
        },
      };
    case findPhysiopalDeviceClear.type:
      return {
        ...state,
        physiopalDevice: {
          find: {
            ...initialState.physiopalDevice.find,
          },
          link: {
            ...state.physiopalDevice.link,
          }
        },
      }
    case findPhysiopalDeviceLink.type:
      return {
        ...state,
        physiopalDevice: {
          find: {
            ...initialState.physiopalDevice.find,
          },
          link: {
            ...state.physiopalDevice.find,
          },
        },
      };
    default:
      return state;
  }
};
