import React, { useState, useEffect } from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import faker from '@faker-js/faker';

import { dateToShortString, mergeDaysForAdherence } from './utils';

const graphOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Adherence Percentage',
        },
        tooltip: {
            callbacks: {
                label: (item) => `${item.dataset.label}: ${item.formattedValue}%`,
            }
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            min: 0,
            max: 100,
        },
    },
};

export default function AdherencePercentage(props) {
    const { data, loading } = props;

    const [labels, setLabels] = useState([]);
    const [missed, setMissed] = useState([]);
    const [completed, setCompleted] = useState([]);

    useEffect(() => {
        if (data.length > 0) {

            const merged_days = mergeDaysForAdherence(data);

            const labels = Object.keys(merged_days).sort((a, b) => new Date(a) - new Date(b)).map(d => dateToShortString(d));
            setLabels(labels);

            const values = Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).map(itm => itm[1]);
            const inner_completed = [];
            const inner_missed = [];

            for (const val of values) {
                inner_completed.push(val);
                inner_missed.push(100 - val);
            }

            setCompleted(inner_completed);
            setMissed(inner_missed);

        }
    }, [data]);

    const graphData = {
        labels,
        datasets: [
            {
                label: 'Completed',
                data: completed,
                backgroundColor: '#22C55E',
            },
            {
                label: 'Missing',
                data: missed,
                backgroundColor: '#FFF',
                borderColor: '#EF4444',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Chart type="bar" options={graphOptions} data={graphData} />
    );
}