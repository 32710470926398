import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkIfSignedInRequest } from '../../store/account';

const signedOutRoutes = [
  '/signin',
  '/password/reset',
  '/password/reset/confirm',
];

const mapStateToProps = (state) => ({
  token: state.account.token,
});

const mapDispatchToProps = (dispatch) => ({
  checkIfSignedIn: () => dispatch(checkIfSignedInRequest()),
});

function AuthGuard(props) {
  const location = useLocation();
  const { token, checkIfSignedIn } = props;
  useEffect(() => {
    if (!signedOutRoutes.includes(location.pathname)) {
      checkIfSignedIn();
    }
  }, [token, checkIfSignedIn]);
  return (
    props.children
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
