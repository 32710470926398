import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Dashboard from '../../../layout/dashboard';

import { findPhysiopalDeviceRequest, findPhysiopalDeviceClear, findPhysiopalDeviceLink } from '../../../../store/devices';

const mapStateToProps = (state) => ({
  device: state.devices.physiopalDevice.find,
});

const mapDispatchToProps = (dispatch) => ({
  findPhysiopalDevice: (serial_number) => dispatch(findPhysiopalDeviceRequest(serial_number)),
  clearPhysiopal: () => dispatch(findPhysiopalDeviceClear()),
  linkPhysiopal: () => dispatch(findPhysiopalDeviceLink()),
});

const getErrorModal = (setModalVisible) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Could not find a Physiopal device matching that serial number
                    </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Double check that the serial number you have entered matches the serial number on the Physiopal device sent to you by Playphysio.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button onClick={() => setModalVisible(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getSuccessModal = (linkPhysiopal) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Physiopal device found
                    </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  This Physiopal device can now be linked to a patient - we'll do that in the next step!
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <Link onClick={() => linkPhysiopal()} to="/physiopal/link" type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm">
              Link to patient
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const FindPhysiopalSchema = Yup.object().shape({
  serial_number: Yup.string()
    .required('The serial number is required'),
});

function New(props) {
  const { device, findPhysiopalDevice, clearPhysiopal, linkPhysiopal } = props;
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    return () => {
      clearPhysiopal();
    }
  }, []);
  return (
    <Dashboard title="Find Physiopal">
      <div className="p-4">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/patients" className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span className="sr-only">Patients</span>
                </Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <p aria-current="page" className="ml-4 text-sm font-medium text-gray-500">Find Physiopal</p>
              </div>
            </li>
          </ol>
        </nav>
        <div className="">
          <Formik
            validationSchema={FindPhysiopalSchema}
            validateOnBlur
            initialValues={{
              serial_number: '',
            }}
            onSubmit={(values) => {
              findPhysiopalDevice(values.serial_number);
              setModalVisible(true);
            }}
          >
            {({
              initialValues,
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              submitForm
            }) => (
              <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                <div className="px-4 py-5 sm:p-6">
                  <div>
                    <label htmlFor="serial_number" className="block text-sm font-medium text-gray-700">Physiopal serial number</label>
                    <div className="mt-1">
                      <input type="text" name="serial_number" id="serial_number" maxLength={32} onChange={handleChange} onBlur={handleBlur} className="shadow-sm focus:ring-purple-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="12345678" aria-describedby="email-description" />
                    </div>
                    <p className="mt-2 text-sm text-gray-500" id="email-description">This is the serial number of the Physiopal hardware provided by Playphysio.</p>
                    {errors.serial_number && <p className="mt-2 text-sm text-red-600" id="email-error">{errors.serial_number}</p>}
                  </div>
                </div>
                <div className="flex px-4 py-4 sm:px-6">
                  <div className="flex-1" />
                  <button type="submit" onClick={handleSubmit} disabled={device.loading} className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${device.loading ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'}`}>
                    {device.loading ? (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : (
                        <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                      )}
                    {device.loading ? 'Searching...' : 'Search for device'}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
      {modalVisible &&
        device.error && getErrorModal(setModalVisible) ||
        device.status && getSuccessModal(linkPhysiopal)
      }
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
