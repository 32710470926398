import React from 'react';

export default function Sent(props) {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <img className="mx-auto h-32 w-auto" src="https://play.physio/wp-content/uploads/2017/02/larger-web-logo-r.png" alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-playphysio">
            Please check your email for a link to reset your password
          </h2>
        </div>
      </div>
    </div >
  );
}