import { createAction } from '@reduxjs/toolkit';

export const getConsentObjectsRequest = createAction('GET_CONSENT_OBJECTS');
export const getConsentObjectsFailure = createAction('GET_CONSENT_OBJECTS_FAILURE');
export const getConsentObjectsSuccess = createAction('GET_CONSENT_OBJECTS_SUCCESS');

const initialState = {
  objects: [],
  loading: false,
};

export default function consentReducer(state = initialState, action) {
  switch (action.type) {
    case getConsentObjectsRequest.type:
      return {
        ...state,
        objects: [],
        loading: true,
      };
    case getConsentObjectsFailure.type:
      return {
        ...state,
        loading: false,
      };
    case getConsentObjectsSuccess.type:
      return {
        ...state,
        objects: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
