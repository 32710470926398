import React, { useEffect, useState } from 'react';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import faker from '@faker-js/faker';

import { minutesToMinutesAndSeconds, dateToShortString, mergeDaysForSessionDuration, generateRandomColor } from './utils';

const graphOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Session Duration (minutes)',
        },
        tooltip: {
            callbacks: {
                label: (item) => `${item.dataset.label}: ${minutesToMinutesAndSeconds(item.raw)}`,
            }
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            ticks: {
                callback: function(value, index, ticks) {
                    return minutesToMinutesAndSeconds(value);
                }
            },
        },
    },
};

export default function AdherencePercentage(props) {
    const { data, loading } = props;
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);

    const colors = [
        '#812C7C',
        '#22C55E',
        '#EAB308',
        '#06B6D4',
        '#6366F1',
        '#D946EF',
        '#EC4899',
        '#F43F5E',
        '#84CC16',
        '#3B82F6',
    ];

    useEffect(() => {
        if (data.length > 0) {
            const merged_days = mergeDaysForSessionDuration(data);

            const labels = Object.keys(merged_days).sort((a, b) => new Date(a) - new Date(b)).map(d => dateToShortString(d));
            setLabels(labels);

            let datasets = [];

            let blah = {};

            Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).forEach(itm => {
                itm[1].forEach((val, idx) => {
                    if (!blah[idx]) {
                        blah[idx] = [];
                    }
                    blah[idx].push(val);
                });
            });

            Object.entries(blah).sort((a, b) => b < a).map(itm => {
                datasets.push(
                    {
                        label: `Session ${parseInt(itm[0]) + 1}`,
                        data: itm[1],
                        backgroundColor: colors.pop() || generateRandomColor(),
                        barPercentage: 1,
                    }
                )
            });

            setDatasets(datasets);
        }
    }, [data]);

    const graphData = {
        labels,
        datasets: datasets,
    };

    return (
        <Chart type="bar" options={graphOptions} data={graphData} />
    );
}