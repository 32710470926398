const therapy_devices = {
  1: 'Aerobika',
  2: 'Acapella',
  3: 'Shaker',
  4: 'Pari',
  5: 'Flutter',
};

const therapy_devices_id = {
  'Aerobika': 1,
  'Acapella': 2,
  'Shaker': 3,
  'Pari': 4,
  'Flutter': 5,
};

const session_types = {
  'Structured session': 1,
  'Timed session': 2,
};

const session_types_rev = {
  1: 'Structured session',
  2: 'Timed session'
};

export async function getLatestSessionPlan(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/session_plans/latest/?playphysio_id=${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  let json;
  try {
    json = await response.json();
  } finally {
    if (json['therapy_device_id'] !== null) {
      json['therapy_device'] = therapy_devices[json['therapy_device_id']];
    }
    json['session_type'] = session_types_rev[json['session_type']];
    return [response, json];
  }
};

export async function createSessionPlan(data = {}) {
  delete data.reminders;
  data['session_type'] = session_types[data['session_type']];
  data['therapy_device_id'] = therapy_devices_id[data['therapy_device']];
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/session_plans/`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'session_plan': data,
    }),
  });
  let json;
  try {
    json = await response.json();
  } finally {
    if (json['therapy_device_id'] !== null) {
      json['therapy_device'] = therapy_devices[json['therapy_device_id']];
    }
    return [response, json];
  }
};
