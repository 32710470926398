import { ConnectedRouter } from 'connected-react-router';

import { Provider } from 'react-redux';

import {
  Switch,
  Route
} from 'react-router-dom';

import store, { history } from './store';

import AuthGuard from './components/guards/AuthGuard';
import Error from './components/pages/Error';

import Home from './components/pages/Home';

import PatientsList from './components/pages/Patients/List';
import PatientOverview from './components/pages/Patients/Overview';
import PatientView from './components/pages/Patients/View';
import Adherence from './components/pages/Patients/Adherence';
import Graphs from './components/pages/Patients/Graphs';
import PrintableGraphs from './components/pages/Patients/Graphs/Printable';
import NewSessionPlan from './components/pages/SessionPlans/New';
import PracticeBlows from './components/pages/Patients/Practice';

import FindPhysiopal from './components/pages/Devices/FindPhysiopal';
import LinkPhysiopal from './components/pages/Devices/Link';

import SignIn from './components/pages/SignIn';
import PasswordReset from './components/pages/ResetPassword/Request';
import PasswordResetConfirm from './components/pages/ResetPassword/Confirm';
import PasswordResetSent from './components/pages/ResetPassword/Sent';

import TeamList from './components/pages/Team/List';
import TeamDetail from './components/pages/Team/Detail';
import NewTeamUser from './components/pages/Team/New';
import Subscription from './components/pages/Subscription';

import SurveyList from './components/pages/Surveys/List';
import ViewSurvey from './components/pages/Surveys/View';

import Reporting from './components/pages/Reporting';
import ReportingSelectPatients from './components/pages/Reporting/SelectPatients';

import './main.css';

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthGuard>
          <Switch>
            <Route exact path="/error">
              <Error />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/signin">
              <SignIn />
            </Route>
            <Route exact path="/password/reset">
              <PasswordReset />
            </Route>
            <Route path="/password/reset/sent">
              <PasswordResetSent />
            </Route>
            <Route path="/password/reset/confirm">
              <PasswordResetConfirm />
            </Route>
            <Route exact path="/physiopal/find">
              <FindPhysiopal />
            </Route>
            <Route exact path="/physiopal/link">
              <LinkPhysiopal />
            </Route>"
            <Route path="/patients/:id/overview">
              <PatientOverview />
            </Route>
            <Route path="/patients/:id/edit">
              <PatientView />
            </Route>
            <Route path="/patients/:id/adherence">
              <Adherence />
            </Route>
            <Route path="/patients/:id/graphs/printable/:from_date/:to_date">
              <PrintableGraphs />
            </Route>
            <Route path="/patients/:id/graphs">
              <Graphs />
            </Route>
            <Route path="/patients/:id/practice">
              <PracticeBlows />
            </Route>
            <Route path="/patients/:id/routines/new">
              <NewSessionPlan />
            </Route>
            <Route path="/surveys/:survey_id">
              <ViewSurvey />
            </Route>
            <Route path="/patients">
              <PatientsList />
            </Route>
            <Route exact path="/team/new">
              <NewTeamUser />
            </Route>
            <Route path="/team/:id">
              <TeamDetail />
            </Route>
            <Route path="/team">
              <TeamList />
            </Route>
            <Route path="/subscription">
              <Subscription />
            </Route>
            <Route path="/surveys">
              <SurveyList />
            </Route>
            <Route path="/reporting/patients">
             <ReportingSelectPatients />
            </Route>
            <Route path="/reporting">
              <Reporting />
            </Route>
          </Switch>
        </AuthGuard>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
