import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import {
  getSurveyRequest,
  getSurveyFailure,
  getSurveySuccess,
  researcherGetSurveysRequest,
  researcherGetSurveysFailure,
  researcherGetSurveysSuccess
} from '../store/surveys';

import { getSurvey, researcherGetSurveys } from '../api/surveys';

const getToken = state => state.account.token;

function* getSurveySaga(action) {
  const token = yield select(getToken);
  const [response, response_json] = yield call(getSurvey, {
    token,
    survey_uuid: action.payload,
  });
  if (response.status === 200) {
    yield put(getSurveySuccess(response_json));
  } else {
    yield put(getSurveyFailure());
  }
}

function* researcherGetSurveysSaga() {
  const token = yield select(getToken);
  const [response, response_json] = yield call(researcherGetSurveys, {
    token,
  });
  if (response.status === 200) {
    yield put(researcherGetSurveysSuccess(response_json));
  } else {
    yield put(researcherGetSurveysFailure());
  }
}

export default function* surveySaga() {
  yield takeEvery(getSurveyRequest.type, getSurveySaga);
  yield takeEvery(researcherGetSurveysRequest.type, researcherGetSurveysSaga);
};
