import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Modal from '../Modal';
import Loading from '../Loading';

import { getSubscriptionRequest, updateSubscriptionRequest } from '../../store/subscription';

const mapStateToProps = state => ({
  subscription: state.subscription,
});

const mapDispatchToProps = dispatch => ({
  get: () => dispatch(getSubscriptionRequest()),
  update: (values) => dispatch(updateSubscriptionRequest(values)),
});

const SubscriptionSchema = Yup.object().shape({
  address: Yup.string()
    .required('Required'),
  additional_info: Yup.object().shape({
    clinical_contact: Yup.string()
      .required('Required'),
    billing_contact: Yup.string()
      .required('Required'),
  }),
  consent_title: Yup.string()
    .required('Required'),
  consent_description: Yup.string()
    .required('Required'),
});

function TeamUserInfo(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const { get, update, subscription } = props;
  useEffect(() => {
    get();
  }, []);
  if (subscription.loading) {
    return <div className="p-4">
      <Loading />
    </div>
  }
  return (
    <Formik
      validationSchema={SubscriptionSchema}
      initialValues={{
        ...subscription,
      }}
      onSubmit={(values) => {
        update(values);
        setModalVisible(false);
      }}
    >
      {({
        initialValues,
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        submitForm
      }) => (
        <>
          <div className="m-4 bg-white shadow rounded-lg divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6">
              <h2>{subscription.name}</h2>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea onChange={handleChange} value={values.address} id="address" name="address" rows="3" className="max-w-lg shadow-sm block w-full focus:ring-purple-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          {errors.address &&
                            <p className="mt-2 text-sm text-red-600" id="email-error">{errors.address}</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="clinical_contact" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Clinical contact
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea onChange={handleChange} value={values.additional_info.clinical_contact} id="clinical_contact" name="additional_info.clinical_contact" rows="3" className="max-w-lg shadow-sm block w-full focus:ring-purple-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          {errors.clinical_contact &&
                            <p className="mt-2 text-sm text-red-600" id="email-error">{errors.clinical_contact}</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="billing_contact" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Billing contact
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea onChange={handleChange} value={values.additional_info.billing_contact} id="billing_contact" name="additional_info.billing_contact" rows="3" className="max-w-lg shadow-sm block w-full focus:ring-purple-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          {errors.billing_contact &&
                            <p className="mt-2 text-sm text-red-600" id="email-error">{errors.billing_contact}</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="consent_title" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Consent title
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <input onChange={handleChange} value={values.consent_title} onChange={handleChange} type="text" name="consent_title" id="consent_title" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                          </div>
                          {errors.consent_title &&
                            <p className="mt-2 text-sm text-red-600" id="email-error">{errors.consent_title}</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="consent_description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Consent description
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <textarea onChange={handleChange} value={values.consent_description} id="consent_description" name="consent_description" rows="3" className="max-w-lg shadow-sm block w-full focus:ring-purple-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                          </div>
                          {errors.consent_description &&
                            <p className="mt-2 text-sm text-red-600" id="email-error">{errors.consent_description}</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="expiry_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Expiry date
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {subscription.expiry_date}
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="max_number_of_patients" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Maximum number of patients
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {subscription.max_number_of_patients}
                          </dd>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                        <label htmlFor="uuid" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Playphysio subscription ID
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {subscription.uuid}
                          </dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    <button type="button" onClick={() => setModalVisible(true)} className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {modalVisible &&
            <Modal
              title="Update subscription"
              content={`This will update the subscription infortmation for ${subscription.name}`}
              buttons={
                <>
                  <button type="submit" onClick={submitForm} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:col-start-2 sm:text-sm">
                    Confirm
                  </button>
                  <button type="button" onClick={() => setModalVisible(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                    Cancel
                  </button>
                </>
              }
            />
          }
        </>
      )
      }
    </Formik >
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserInfo);
