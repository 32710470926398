import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import { signIn } from '../../../store/account';

import TopBanner from '../../Banners/TopBanner';

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password) => dispatch(signIn({
    username,
    password
  })),
});

function SignIn(props) {
  const { account } = props;
  return (
    <>
      {account.error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
          <p className="text-center font-bold">Invalid email or password. Please try again.</p>
        </div>
      )}
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-32 w-auto" src="https://play.physio/wp-content/uploads/2017/02/larger-web-logo-r.png" alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-playphysio">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{ username: '', password: '' }}
              onSubmit={(values) => {
                props.signIn(values.username, values.password);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting
              }) => (
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="email" className={`block text-sm font-medium ${account.error != null ? ('text-red-700') : ('text-gray-700')}`}>
                      Email address
                    </label>
                    <div className="mt-1">
                      <input id="email" name="username" onChange={handleChange} type="email" autoComplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className={`block text-sm font-medium ${account.error != null ? ('text-red-700') : ('text-gray-700')}`}>
                      Password
                    </label>
                    <div className="mt-1">
                      <input id="password" name="password" onChange={handleChange} type="password" autoComplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <div>
                    <button type="submit" disabled={isSubmitting} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-playphysio hover:bg-playphysio-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                      Sign in
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Link to="/password/reset" className="flex justify-center mt-4 items-center text-playphysio cursor-pointer font-bold">
            <svg className="h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Forgot your password?
          </Link>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
