import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { getRemindersRequest, createReminderRequest, deleteReminderRequest } from '../../../store/reminders';

const mapStateToProps = state => ({
  reminders: state.reminders.reminders,
  loading: state.reminders.loading,
  session_plan_error: state.session_plans.view.error,
});

const mapDispatchToProps = dispatch => ({
  getReminders: (id) => dispatch(getRemindersRequest(id)),
  createReminder: (reminder) => dispatch(createReminderRequest(reminder)),
  deleteReminder: (data) => dispatch(deleteReminderRequest(data)),
});

const REMINDER_TYPE_MAP = {
  'time': 'Time offset',
  'blowset': 'Blowset completion',
  'position': 'Position completion',
  'session': 'Session completion'
};

function RemindersCard(props) {
  const { id } = useParams();
  const { getReminders, reminders, createReminder, deleteReminder, session_plan_error } = props;
  const [addingReminder, setAddingReminder] = useState(false);
  useEffect(() => {
    getReminders(id);
  }, []);
  if (session_plan_error == 404) {
    return (
      <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div className="px-4 py-5 sm:px-6 flex">
          <div className="flex-1">
            Reminders
        </div>
        </div>
        <div className="py-4">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
            <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">z
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div className="flex-1 text-center mt-4">
            <p>An active therapy routine is needed to create reminders.</p>
          </div>
          <div className="flex mt-4 justify-center">
            <Link to={`/patients/${id}/routines/new`} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              <svg className="-ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              Create one
            </Link>
          </div>
        </div>
      </div>
    );
  }
  const rows = [];
  reminders.map((reminder, reminderIdx) => {
    rows.push(
      <tr key={`reminder-${reminderIdx}`} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {REMINDER_TYPE_MAP[reminder.reminder_type]}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {reminder.reminder_type === 'time' ? reminder.time_offset : '-'}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {reminder.text}
        </td>
        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
          <button onClick={() => deleteReminder({
            playphysio_id: id,
            uuid: reminder.uuid,
          })} type="submit" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
            Delete
          </button>
        </td>
      </tr>
    );
  });
  return (
    <div data-testid="reminders-card" className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6 flex">
        <div className="flex-1">
          Reminders
        </div>
        <div className="flex-shrink">
        <button onClick={() => setAddingReminder(!addingReminder)} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              {addingReminder ? 'Cancel' : 'Add reminder'}
          </button>
        </div>
      </div>
      <div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Time offset
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Text
              </th>
              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
            {addingReminder &&
              <Formik
              validateOnBlur
              initialValues={{
                type: 'Time offset',
                offset: 3,
                text: '',
              }}
              onSubmit={(values) => {
                createReminder({
                  playphysio_id: id,
                  reminder: values,
                });
                setAddingReminder(false);
              }}
            >
              {({
                initialValues,
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                submitForm
              }) => (
                <tr className="bg-white">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <select onChange={handleChange} value={values.type} id="type" name="type" className="block w-full text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option>Time offset</option>
                    <option>Blowset completion</option>
                    <option>Position completion</option>
                    <option>Session completion</option>
                  </select>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {values.type === 'Time offset' ?
                    <input onChange={handleChange} value={values.offset} type="number" name="offset" className="shadow-sm focus:ring-purple-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                  : '-'
                  }
                </td>
                <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                  <textarea onChange={handleChange} value={values.text} name="text" className="shadow-sm focus:ring-purple-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                </td>
                <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                <button onClick={submitForm} type="submit" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                  Create
                </button>
                </td>
              </tr>
              )}
              </Formik>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindersCard);
