import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Dashboard from '../../../layout/dashboard';

import Loading from '../../../Loading';

import { getPatients, clearPatientView } from '../../../../store/patients';

const mapStateToProps = state => ({
  loading: state.patients.loading,
  patients: state.patients.list,
});

const mapDispatchToProps = dispatch => ({
  getPatients: () => dispatch(getPatients()),
  clearPatientView: () => dispatch(clearPatientView()),
});

function New(props) {
  const { loading, patients, getPatients, clearPatientView } = props;

  const [selections, setSelections] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    getPatients();
  }, [getPatients]);

  console.log(selections);

  const rows = [];
  patients.sort((a, b) => {
    if (!a.name) return 1;
    if (!b.name) return -1;
  }).map(patient => {
    rows.push(
      <tr key={patient.playphysio_id} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {patient.playphysio_id}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {patient.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {patient.secret_word}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {patient.date_of_birth}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium sm:px-6 lg:px-8">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-playphysio focus:ring-purple-500"
            checked={selections.includes(patient.playphysio_id)}
            onChange={event => {
              const isChecked = event.target.checked;

              setSelectedAll(false);

              if (isChecked) {
                setSelections([...selections, patient.playphysio_id])
              } else {
                const filteredList = selections.filter((item) => item !== patient.playphysio_id)
                setSelections(filteredList)
              }
            }}
          />
        </td>
      </tr >
    )
  });

  return (
    <Dashboard title="Reporting">
      <div className="p-4">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/reporting" className="text-gray-400 hover:text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                  </svg>
                  <span className="sr-only">Patients</span>
                </Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <p aria-current="page" className="ml-4 text-sm font-medium text-gray-500">Export patient data</p>
              </div>
            </li>
          </ol>
        </nav>
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Playphysio ID
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Secret animal
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date of birth
                      </th>
                      <th scope="col" className="cursor-pointer lg:px-8 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-playphysio focus:ring-purple-500"
                          checked={selectedAll}
                          onChange={event => {
                            const isChecked = event.target.checked;

                            if (isChecked) {
                              var items = [];
                              for (const patient of patients) {
                                items.push(patient.playphysio_id)
                              }
                              setSelections(items)
                              setSelectedAll(true);
                            } else {
                              setSelections([])
                              setSelectedAll(false);
                            }
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <div className="p-4">
                        <Loading />
                      </div>
                    ) : (
                      rows
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex px-4 py-4 sm:px-6">
            <div className="flex-1" />
            <button type="submit" onClick={() => { }} disabled={selections.length === 0} className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${selections.length === 0 ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'}`}>
              Next
            </button>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
