import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Transition } from '@headlessui/react'
import { Link, useParams } from 'react-router-dom';

import Logo from '../../../../assets/colour-landscape-logo-on-white.png';

import { getPatientRequest } from '../../../../store/patients';
import { getReportRequest, setFromDate, setToDate, resetDates } from '../../../../store/reporting';

import AdherencePercentage from './AdherencePercentage';
import SessionCompletionPercentage from './SessionCompletionPercentage';
import PlayphysioScores from './PlayphysioScores';
import SessionDurations from './SessionDurations';
import SessionStartEnd from './SessionStartEnd';
import CompleteIncompletePrescribed from './CompleteIncompletePrescribed';

import { substractDaysFromToday, isToday } from './utils';

const mapStateToProps = (state) => ({
    patients: state.patients.list,
    patient: state.patients.view,
    reporting: state.reporting,
    from: state.reporting.from,
    to: state.reporting.to,
});

const mapDispatchToProps = (dispatch) => ({
    getPatient: (playphysio_id) => dispatch(getPatientRequest(playphysio_id)),
    getReport: (playphysio_id, from, to) => dispatch(getReportRequest({
        playphysio_id,
        from: from,
        to: to,
    })),
    setFrom: (date) => dispatch(setFromDate(date)),
    setTo: (date) => dispatch(setToDate(date)),
    reset: () => dispatch(resetDates()),
});

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Graphs(props) {
    const { patients, patient, getPatient, getReport, reporting, setFrom, setTo, from, to, reset } = props;
    const { id, from_date, to_date } = useParams();

    useEffect(() => {
        getPatient(id);
        if (from_date && to_date) {
            setFrom(from_date);
            setTo(to_date);
            getReport(id, from_date, to_date);
        }
    }, [patients, getPatient, getReport, from, to]);

    let data = [];

    for (const report of reporting.data) {
        if (report) {
            let required_today = [];
            if (isToday(to)) {
                required_today = report.required_today || [];
            }
            data = [
                ...data,
                ...report.sessions || [],
                ...report.missed_sessions || [],
                ...required_today,
            ].sort((a, b) => a.datetime < b.datetime ? 1 : -1).sort((a, b) => a.playphysio_id < b.playphysio_id);
        }
    }

    return (
        <div className="max-w-full">
            <div className="w-full p-4 bg-white border-b border-gray-200 flex items-center">
                <img src={Logo} />
                <div className="flex-1 flex justify-end">
                    <div className="flex flex-col">
                        <h1>Playphysio ID: {patient.playphysio_id}</h1>
                        <h1>Secret animal: {patient.secret_word}</h1>
                        <h1>Date: {new Date().toDateString()}</h1>
                    </div>
                </div>
            </div>
            <div className="px-4 pb-4 grid grid-cols-1 gap-4">
                <div className="col-span-1 bg-white py-2 px-4 shadow rounded-lg p-break">
                    <AdherencePercentage maintainAspectRatio={true} data={data} loading={reporting.loading} />
                </div>
                <div className="col-span-3 bg-white py-2 px-4 shadow rounded-lg p-break">
                    <SessionCompletionPercentage data={data} loading={reporting.loading} />
                </div>
                <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg p-break">
                    <CompleteIncompletePrescribed data={data} loading={reporting.loading} />
                </div>
                <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg p-break">
                    <SessionDurations data={data} loading={reporting.loading} />
                </div>
                <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg p-break">
                    <SessionStartEnd data={data} loading={reporting.loading} />
                </div>
                <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg p-break">
                    <PlayphysioScores data={data} loading={reporting.loading} />
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Graphs);
