import { createAction } from '@reduxjs/toolkit';

export const getAppointmentsRequest = createAction('GET_APPOINTMENTS_REQUEST');
export const getAppointmentsFailure = createAction('GET_APPOINTMENTS_FAILURE');
export const getAppointmentsSuccess = createAction('GET_APPOINTMENTS_SUCCESS');

export const createAppointmentRequest = createAction('CREATE_APPOINTMENT_REQUEST');
export const createAppointmentFailure = createAction('CREATE_APPOINTMENT_FAILURE');
export const createAppointmentSuccess = createAction('CREATE_APPOINTMENT_SUCCESS');

export const deleteAppointmentRequest = createAction('DELETE_APPOINTMENT_REQUEST');
export const deleteAppointmentFailure = createAction('DELETE_APPOINTMENT_FAILURE');
export const deleteAppointmentSuccess = createAction('DELETE_APPOINTMENT_SUCCESS');

const initialState = {
  appointments: [],
  loading: false,
};

export default function appointments(state = initialState, action) {
  switch (action.type) {
    case getAppointmentsRequest.type:
      return {
        appointments: [],
        loading: true,
      };
    case getAppointmentsFailure.type:
      return {
        ...state,
        loading: false,
      };
    case getAppointmentsSuccess.type:
      return {
        ...state,
        appointments: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
