import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import Modal from '../Modal';
import Loading from '../Loading';

import { createTeamUserRequest, updateTeamUserRequest } from '../../store/team';

const mapStateToProps = state => ({
  loading: state.patients.view.loading,
});

const mapDispatchToProps = dispatch => ({
  create: (values) => dispatch(createTeamUserRequest(values)),
  update: (values) => dispatch(updateTeamUserRequest(values)),
});

const USER_TYPES = {
  'therapist': 'Therapist',
  'Therapist': 'therapist',
  'research': 'Researcher',
  'Researcher': 'research',
};

function TeamUserInfo(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const { loading, person, create } = props;
  if (loading) {
    return (
      <div className="p-4"><Loading /></div>
    );
  }
  return (
    <Formik
      initialValues={{
        user_type: 'therapist',
        ...person,
      }}
      onSubmit={(values) => {
        if (person == null) {
          // create
          create(values);
        } else {
          // update
          props.update(values);
          setModalVisible(false);
        }
      }}
    >
      {({
        initialValues,
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        submitForm
      }) => (
        <>
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Personal information
                  </h3>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="user_type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      User type
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <select
                          id="user_type"
                          name="user_type"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          defaultValue="Therapist"
                          onChange={handleChange}
                        >
                          <option value="therapist">Therapist</option>
                          <option value="research">Researcher</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      First name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input value={values.first_name} onChange={handleChange} type="text" name="first_name" id="first_name" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Last name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input value={values.last_name} onChange={handleChange} type="text" name="last_name" id="last_name" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Email address
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input value={values.email} onChange={handleChange} type="email" name="email" id="email" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                    </div>
                  </div>
                </div>
                {initialValues.name == null &&
                  <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Password
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                          <input value={values.password} onChange={handleChange} type="password" name="password" id="password" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                {initialValues.name == null ? (
                  <>
                    <Link to="/team">
                      <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        Cancel
                      </button>
                    </Link>
                    <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                      Save
                    </button>
                  </>
                ) : (
                  <button type="button" onClick={() => setModalVisible(true)} className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Save
                  </button>
                )}
              </div>
            </div>
          </form>
          {modalVisible &&
            <Modal
              title="Update patient"
              content={`This will update the personal information for ${person.name}.`}
              buttons={
                <>
                  <button type="submit" onClick={submitForm} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:col-start-2 sm:text-sm">
                    Confirm
                  </button>
                  <button type="button" onClick={() => setModalVisible(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                    Cancel
                  </button>
                </>
              }
            />
          }
        </>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamUserInfo);
