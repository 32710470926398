import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getConsentObjectsRequest } from '../../../store/consent';

const mapStateToProps = state => ({
  objects: state.consent.objects,
});

const mapDispatchToProps = dispatch => ({
  getConsentObjects: (playphysio_id) => dispatch(getConsentObjectsRequest(playphysio_id)),
});

function ConsentCard(props) {
  const { id } = useParams();
  const { getConsentObjects, objects } = props;
  useEffect(() => {
    getConsentObjects(id);
  }, [id, getConsentObjects]);
  const rows = [];
  objects.map((object, objectIdx) => {
    rows.push(
      <tr key={`consent-${objectIdx}`} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {object.consent_title}
        </td>
        <td className="px-6 py-4 text-sm text-gray-500">
          {object.consent_description}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${!object.content_status ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
            {!object.consent_status ? 'Consent not given' : 'Consent given'}
          </span>
        </td>
      </tr>
    );
  });
  return (
    <div data-testid="consent-card" className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6 flex">
        <div className="flex-1">
          Consent
        </div>
      </div>
      <div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsentCard);
