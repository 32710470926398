import React, { useEffect, useState } from 'react';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { dateToShortString, mergeDaysForPlayphysioScore } from './utils';

const graphOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Playphysio Scores',
        },
    },
    scales: {
        y: {
            min: 0,
        },
    },
};

export default function AdherencePercentage(props) {
    const { data, loading } = props;
    const [labels, setLabels] = useState([]);
    const [scores, setScores] = useState([]);
    useEffect(() => {
        if (data.length > 0) {

            const merged_days = mergeDaysForPlayphysioScore(data);

            const labels = Object.keys(merged_days).sort((a, b) => new Date(a) - new Date(b)).map(d => dateToShortString(d));
            setLabels(labels);

            const values = Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).map(itm => itm[1]);
            setScores(values);

        }
    }, [data]);

    const graphData = {
        labels,
        datasets: [
            {
                label: 'Score',
                data: scores,
                backgroundColor: '#812C7C',
                fill: true,
            },
        ],
    };

    return (
        <Chart type="line" options={graphOptions} data={graphData} />
    );
}