import { createAction } from '@reduxjs/toolkit';

export const getSurveyRequest = createAction('GET_SURVEY_REQUEST');
export const getSurveyFailure = createAction('GET_SURVEY_FAILURE');
export const getSurveySuccess = createAction('GET_SURVEY_SUCCESS');

export const researcherGetSurveysRequest = createAction('RESEARCHER_GET_SURVEYS_REQUEST');
export const researcherGetSurveysFailure = createAction('RESEARCHER_GET_SURVEYS_FAILURE');
export const researcherGetSurveysSuccess = createAction('RESEARCHER_GET_SURVEYS_SUCCESS');

const initialState = {
  loading: true,
  items: []
};

export default function surveys(state = initialState, action) {
  switch (action.type) {
    case getSurveyRequest.type:
      return {
        ...state,
        loading: true,
      };
    case getSurveyFailure.type:
      return {
        ...state,
        loading: false,
      };
    case getSurveySuccess.type:
      return {
        ...state,
        items: [
          ...state.items,
          action.payload
        ],
        loading: false,
      };
    case researcherGetSurveysRequest.type:
      return {
        ...initialState,
        loading: true,
      };
    case researcherGetSurveysFailure.type:
      return {
        ...initialState,
        loading: false,
      };
    case researcherGetSurveysSuccess.type:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    default:
      return state;
  }
};
