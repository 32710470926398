import React, { useEffect, useState } from 'react';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { dateToShortString, mergeDaysForCompleteIncompletePrescribed } from './utils';

const graphOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Number of Complete, Incomplete and Prescribed Blows',
        },
        tooltip: {
            callbacks: {
                label: (item) => `${item.dataset.label}: ${item.formattedValue} blows`,
            }
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

export default function AdherencePercentage(props) {
    const { data, loading } = props;
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            const merged_days = mergeDaysForCompleteIncompletePrescribed(data);

            const labels = Object.keys(merged_days).sort((a, b) => new Date(a) - new Date(b)).map(d => dateToShortString(d));
            setLabels(labels);

            let datasets = [];

            datasets.push(
                {
                    label: `Prescribed`,
                    data: Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).map(d => d[1].prescribed),
                    backgroundColor: '#812C7C',
                    barPercentage: 1,
                    type: 'line',
                }
            )

            datasets.push(
                {
                    label: `Complete`,
                    data: Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).map(d => d[1].complete),
                    backgroundColor: '#22C55E',
                    barPercentage: 1,
                }
            )

            datasets.push(
                {
                    label: `Incomplete`,
                    data: Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).map(d => d[1].incomplete),
                    backgroundColor: '#EAB308',
                    barPercentage: 1,
                }
            )

            setDatasets(datasets);

        }
    }, [data]);

    const graphData = {
        labels,
        datasets: datasets,
        // datasets: [
        //     {
        //         label: 'tese',
        //         data: Array.from({ length: 100 }, () => ({
        //             x: faker.datatype.number({ min: -100, max: 100 }),
        //             y: faker.datatype.number({ min: -100, max: 100 }),
        //         })),
        //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //     },
        //     {
        //         label: 'Completed',
        //         data: Array.from({ length: 100 }, () => ({
        //             x: faker.datatype.number({ min: -100, max: 100 }),
        //             y: faker.datatype.number({ min: -100, max: 100 }),
        //         })),
        //         backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //     },
        // ],
    };

    return (
        <Chart type="bar" options={graphOptions} data={graphData} />
    );
}