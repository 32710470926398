import { createAction } from '@reduxjs/toolkit';

export const checkIfSignedInRequest = createAction('CHECK_IF_SIGNED_IN');
export const signIn = createAction('SIGN_IN');
export const signInFailure = createAction('SIGN_IN_FAILURE');
export const signInSuccess = createAction('SIGN_IN_SUCCESS');

export const meSuccess = createAction('ME_SUCCESS');

export const resetPasswordRequest = createAction('REQUEST_PASSWORD_RESET');
export const resetPasswordConfirm = createAction('RESET_PASSWORD_CONFIRM');

export const signOut = createAction('SIGN_OUT');

const initialState = {
  error: null,
  token: null,
  loading: true,
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case signIn.type:
      return {
        ...state,
        loading: true,
      };
    case signInFailure.type:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case signInSuccess.type:
      return {
        ...state,
        token: action.payload.token,
      };
    case signOut.type:
      return initialState;
    case meSuccess.type:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    default:
      return state;
  }
};
