import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  createPatientRequest,
  createPatientFailure,
  createPatientSuccess,
  getPatientRequest,
  getPatientFailure,
  getPatientSuccess,
  updatePatientRequest,
  updatePatientFailure,
  updatePatientSuccess,
  getPatients,
  getPatientsFailure,
  getPatientsSuccess,
  getPatientDevicesRequest,
  getPatientDevicesSuccess,
  pauseDeviceRequest,
  pauseDeviceRequestFailure,
  pauseDeviceRequestSuccess,
  getSurveysSuccess
} from '../store/patients';

import { patients, getPatient, updatePatient, createPatient } from '../api/patients';
import { getDevices, pauseDevice } from '../api/devices';
import { getSurveys } from '../api/surveys';

const getToken = state => state.account.token;

function* createPatientSaga(action) {
  const token = yield select(getToken);
  const response = yield call(createPatient, {
    token,
    ...action.payload,
  });
  yield put(createPatientSuccess(response));
  yield put(push(`/patients/${response.playphysio_id}/overview`));
}

function* getPatientSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getPatient, {
    token,
    playphysio_id: action.payload,
  });
  const surveys = yield call(getSurveys, {
    token,
    playphysio_id: action.payload,
  });
  yield put(getPatientSuccess(response));
  yield put(getSurveysSuccess(surveys));
}

function* updatePatientSaga(action) {
  const token = yield select(getToken);
  const response = yield call(updatePatient, {
    token,
    ...action.payload,
  });
  yield put(getPatientSuccess(response));
}

function* getPatientsSaga() {
  const token = yield select(getToken);
  const response = yield call(patients, {
    token,
  });
  yield put(getPatientsSuccess(response));
};

function* getPatientDevicesSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getDevices, {
    token,
    playphysio_id: action.payload,
  });
  yield put(getPatientDevicesSuccess(response));
}

function* pauseDeviceSaga(action) {
  const token = yield select(getToken);
  const response = yield call(pauseDevice, {
    token,
    ...action.payload,
  });
  yield put(pauseDeviceRequestSuccess(action.payload.playphysio_id));
}

export default function* patientsSaga() {
  yield takeEvery(createPatientRequest.type, createPatientSaga);
  yield takeEvery(getPatientRequest.type, getPatientSaga);
  yield takeEvery(updatePatientRequest.type, updatePatientSaga);
  yield takeEvery(getPatients.type, getPatientsSaga);
  yield takeEvery(getPatientDevicesRequest.type, getPatientDevicesSaga);
  yield takeEvery(pauseDeviceRequest.type, pauseDeviceSaga);
  yield takeEvery(pauseDeviceRequestSuccess.type, getPatientDevicesSaga);
};
