import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { getPatientRequest, clearPatientView } from '../../../../store/patients';
import { getLatestSessionPlanRequest } from '../../../../store/session_plans';

import Dashboard from '../../../layout/dashboard';
import PatientInfo from '../../../PatientInfo';

const mapStateToProps = state => ({
  patient: state.patients.view,
});

const mapDispatchToProps = dispatch => ({
  getPatient: (playphysio_id) => dispatch(getPatientRequest(playphysio_id)),
  clearPatientView: () => dispatch(clearPatientView()),
});

function PatientView(props) {
  const { id } = useParams();
  const { patient, getPatient, clearPatientView } = props;
  useEffect(() => {
    getPatient(id);
    return () => {
      clearPatientView();
    }
  }, []);
  return (
    <Dashboard title={patient.name}>
      <div className="p-4">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/patients" className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span className="sr-only">Patients</span>
                </Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link to={`/patients/${patient.playphysio_id}/overview`} aria-current="page" className="ml-4 text-sm font-medium text-gray-500">{patient.name}</Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <p className="ml-4 text-sm font-medium text-gray-500">Edit details</p>
              </div>
            </li>
          </ol>
        </nav>
        <div className="bg-white overflow-hidden shadow sm:rounded-lg">
          <div className="px-4 sm:p-6">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Playphysio ID
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg relative flex rounded-md shadow-sm">
                    <input value={patient.playphysio_id} disabled={true} type="text" name="name" id="name" className="disabled:bg-gray-50 flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Secret word
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg relative flex rounded-md shadow-sm">
                    <input value={patient.secret_word} disabled={true} type="text" name="name" id="name" className="disabled:bg-gray-50 flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 bg-white shadow rounded-lg divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2>Patient information</h2>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <PatientInfo
              patient={patient}
            />
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientView);
