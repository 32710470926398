import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Transition } from '@headlessui/react'
import { Link, useParams } from 'react-router-dom';
import { PrinterIcon } from '@heroicons/react/solid';

import Dashboard from '../../../layout/dashboard';

import { getPatientRequest } from '../../../../store/patients';
import { getReportRequest, setFromDate, setToDate, resetDates } from '../../../../store/reporting';

import AdherencePercentage from './AdherencePercentage';
import SessionCompletionPercentage from './SessionCompletionPercentage';
import PlayphysioScores from './PlayphysioScores';
import SessionDurations from './SessionDurations';
import SessionStartEnd from './SessionStartEnd';
import CompleteIncompletePrescribed from './CompleteIncompletePrescribed';

import { substractDaysFromToday, isToday } from './utils';

const mapStateToProps = (state) => ({
  patients: state.patients.list,
  patient: state.patients.view,
  reporting: state.reporting,
  from: state.reporting.from,
  to: state.reporting.to,
});

const mapDispatchToProps = (dispatch) => ({
  getPatient: (playphysio_id) => dispatch(getPatientRequest(playphysio_id)),
  getReport: (playphysio_id, from, to) => dispatch(getReportRequest({
    playphysio_id,
    from: from,
    to: to,
  })),
  setFrom: (date) => dispatch(setFromDate(date)),
  setTo: (date) => dispatch(setToDate(date)),
  reset: () => dispatch(resetDates()),
});

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Graphs(props) {
  const { patients, patient, getPatient, getReport, reporting, setFrom, setTo, from, to, reset } = props;
  const { id } = useParams();

  useEffect(() => {
    getPatient(id);
    getReport(id, from, to);
  }, [patients, getPatient, getReport, from, to]);

  let data = [];

  for (const report of reporting.data) {
    if (report) {
      let required_today = [];
      if (isToday(to)) {
        required_today = report.required_today || [];
      }
      data = [
        ...data,
        ...report.sessions || [],
        ...report.missed_sessions || [],
        ...required_today,
      ].sort((a, b) => a.datetime < b.datetime ? 1 : -1).sort((a, b) => a.playphysio_id < b.playphysio_id);
    }
  }

  return (
    <Dashboard title="Graphs"
      buttons={
        <>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div className="hidden md:visible md:inline-flex space-x-6">
                  <div className="space-x-4">
                    <label htmlFor="from">From</label>
                    <input value={from} onChange={(event) => setFrom(event.target.value)} max={to} type="date" name="from" />
                  </div>
                  <div className="space-x-4">
                    <label htmlFor="to">To</label>
                    <input value={to} onChange={(event) => setTo(event.target.value)} min={from} max={new Date().toISOString().split("T")[0]} type="date" name="to" />
                  </div>
                  <button onClick={() => reset()} type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-playphysio focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Reset
                  </button>
                  <button onClick={() => window.open(`/patients/${patient.playphysio_id}/graphs/printable/${from}/${to}`)} type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-playphysio focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Print
                    <PrinterIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
                  </button>
                </div>
              </>
            )}
          </Menu>
        </>
      }
    >
      {id !== 'all' && (
        <div className="p-4">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
              <li className="flex">
                <div className="flex items-center">
                  <Link to="/patients" className="text-gray-400 hover:text-gray-500">
                    <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    <span className="sr-only">Patients</span>
                  </Link>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link to={`/patients/${patient.playphysio_id}/overview`} aria-current="page" className="ml-4 text-sm font-medium text-gray-500">{patient.name}</Link>
                </div>
              </li>
              <li className="flex">
                <div className="flex items-center">
                  <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <p className="ml-4 text-sm font-medium text-gray-500">Graphs</p>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      )}
      <div className="px-4 pb-4 grid grid-cols-4 gap-4">
        <div className="col-span-1 bg-white py-2 px-4 shadow rounded-lg">
          <AdherencePercentage maintainAspectRatio={false} data={data} loading={reporting.loading} />
        </div>
        <div className="col-span-3 bg-white py-2 px-4 shadow rounded-lg">
          <SessionCompletionPercentage data={data} loading={reporting.loading} />
        </div>
        <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg">
          <CompleteIncompletePrescribed data={data} loading={reporting.loading} />
        </div>
        <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg">
          <SessionDurations data={data} loading={reporting.loading} />
        </div>
        <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg">
          <SessionStartEnd data={data} loading={reporting.loading} />
        </div>
        <div className="col-span-2 bg-white py-2 px-4 shadow rounded-lg">
          <PlayphysioScores data={data} loading={reporting.loading} />
        </div>
      </div>
    </Dashboard >
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Graphs);
