export async function getTeam(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/team`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  const json = await response.json();
  return json;
};

export async function getTeamMember(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/team/${data.id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  const json = await response.json();
  return json;
};

export async function deleteTeamMember(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/team/${data.id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  let json;
  try {
    json = await response.json();
  } finally {
    return [response, json];
  }
};

export async function createTeamUser(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/team`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
};
