import {
  compose,
  configureStore,
  combineReducers
} from '@reduxjs/toolkit';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createSagaMiddleware from 'redux-saga';

import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';

import accountReducer from './account';
import accountSaga from '../sagas/account';

import appointmentsReducer from './appointments';
import appointmentsSaga from '../sagas/appointments';

import consentReducer from './consent';
import consentSaga from '../sagas/consent';

import devicesReducer from './devices';
import devicesSaga from '../sagas/devices';

import patientsReducer from './patients';
import patientsSaga from '../sagas/patients';

import practiceReducer from './practice';
import practiceSaga from '../sagas/practice';

import sessionPlanReducer from './session_plans';
import sessionPlanSaga from '../sagas/session_plans';

import subscriptionReducer from './subscription';
import subscriptionSaga from '../sagas/subscription';

import surveysReducer from './surveys';
import surveysSaga from '../sagas/surveys';

import teamReducer from './team';
import teamSaga from '../sagas/team';

import remindersReducer from './reminders';
import remindersSaga from '../sagas/reminders';

import reportingReducer from './reporting';
import reportingSaga from '../sagas/reporting';

export { default as accountReducer } from './account';
export { default as patientsReducer } from './patients';
export { default as teamReducer } from './team';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

export const rootReducer = (history) => combineReducers({
  account: accountReducer,
  appointments: appointmentsReducer,
  consent: consentReducer,
  devices: devicesReducer,
  patients: patientsReducer,
  practice: practiceReducer,
  reporting: reportingReducer,
  session_plans: sessionPlanReducer,
  subscription: subscriptionReducer,
  surveys: surveysReducer,
  team: teamReducer,
  reminders: remindersReducer,
  router: connectRouter(history),
});

const reducer = compose(
  mergePersistedState()
)(rootReducer(history));

const storage = compose(
  filter('account.token')
)(adapter(window.localStorage));

const enhancer = compose(
  persistState(storage, 'redux')
);

const store = configureStore({
  reducer,
  middleware: [sagaMiddleware, routerMiddleware(history)],
  enhancers: [enhancer],
});

if (window.Cypress) {
  window.store = store;
}

export default store;

sagaMiddleware.run(accountSaga);
sagaMiddleware.run(appointmentsSaga);
sagaMiddleware.run(consentSaga);
sagaMiddleware.run(devicesSaga);
sagaMiddleware.run(patientsSaga);
sagaMiddleware.run(practiceSaga);
sagaMiddleware.run(sessionPlanSaga);
sagaMiddleware.run(subscriptionSaga);
sagaMiddleware.run(surveysSaga);
sagaMiddleware.run(teamSaga);
sagaMiddleware.run(remindersSaga);
sagaMiddleware.run(reportingSaga);
