import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { connect } from 'react-redux';

import Dashboard from '../../../layout/dashboard';

import { getSurveyRequest } from '../../../../store/surveys';

import NotFound from '../../../Cards/404';
import Loading from '../../../Loading';

const mapStateToProps = state => ({
    surveys: state.surveys,
});

const mapDispatchToProps = dispatch => ({
    getSurvey: (id) => dispatch(getSurveyRequest(id)),
});

function ViewSurvey(props) {
    const { surveys, getSurvey } = props;
    const { survey_id } = useParams();

    useEffect(() => {
        getSurvey(survey_id);
    }, [])

    if (surveys.loading) {
        return <Dashboard>
            <div className="p-4"><Loading /></div>
        </Dashboard>;
    }

    const survey = surveys.items.find(s => s.uuid == survey_id) || {};
    const response = survey.survey_response || {};
    const answers = response.answers || [];
    return (
        <Dashboard
            title={survey.name || 'Survey'}
            buttons={answers.length > 0 && (
                <a href={`${process.env.REACT_APP_BASE_URL}/surveys/${survey.uuid}/csv`}>
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Download CSV
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                    </button>
                </a>
            )}
        >
            {survey.uuid ? (
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Question number
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Question
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Value
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {answers.map(answer => (
                            <tr key={answer.name}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{answer.question_number}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{answer.question}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{answer.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <NotFound />
            )}
        </Dashboard>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSurvey);
