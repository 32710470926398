export async function getLatestTherapyDevice(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/therapy_devices/latest?playphysio_id=${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function createTherapyDevice(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/therapy_devices/`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'therapy_device': data,
    }),
  });
  return response.json();
};

export async function getDevices(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/devices/${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function pauseDevice(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/devices/${data.playphysio_id}/${data.serial_number}`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function assignPhysiopalToPatient(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/devices/update`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'playphysio_id': data.playphysio_id,
      'device': {
        'serial_number': data.serial_number,
        'status': 'next',
      },
    }),
  });
  let json;
  try {
    json = await response.json();
  } finally {
    return [response, json];
  }
}

export async function findPhysiopalDevice(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/devices/find`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'serial_number': data.serial_number,
    }),
  });
  let json;
  try {
    json = await response.json();
  } finally {
    return [response, json];
  }
};
