import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import Modal from '../Modal';
import Loading from '../Loading';

import { createPatientRequest, updatePatientRequest } from '../../store/patients';

function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

const mapStateToProps = state => ({
  loading: state.patients.view.loading,
});

const mapDispatchToProps = dispatch => ({
  create: (values) => dispatch(createPatientRequest(values)),
  update: (values) => dispatch(updatePatientRequest(values)),
});

const NewPatientSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  nhs_id: Yup.string()
    .required('Requred'),
  hospital_id: Yup.string()
    .required('Required'),
  date_of_birth: Yup.date()
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  gender: Yup.string()
    .label('Gender')
    .required('Required')
    .oneOf(['male', 'female'])
});

function PatientInfo(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const { loading, patient, create, serial_number } = props;
  if (loading) {
    return <div className="p-4">
      <Loading />
    </div>;
  }
  return (
    <Formik
      validationSchema={NewPatientSchema}
      initialValues={{
        country: 'United Kingdom',
        ...patient,
        serial_number,
      }}
      onSubmit={(values) => {
        const transformedValues = {
          ...values,
          gender: `${values.gender}`.toLowerCase(),
        };
        if (patient == null) {
          // create
          create(transformedValues);
        } else {
          // update
          props.update(transformedValues);
          setModalVisible(false);
        }
      }}
    >
      {({
        initialValues,
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        submitForm,
        isValid
      }) => (
        <>
          <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Personal information
                  </h3>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input value={values.name} onChange={handleChange} type="text" name="name" id="name" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                      {errors.name &&
                        <p className="mt-2 text-sm text-red-600" id="email-error">{errors.name}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="nhs_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      NHS ID
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input value={values.nhs_id} onChange={handleChange} type="text" name="nhs_id" id="nhs_id" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                      {errors.nhs_id &&
                        <p className="mt-2 text-sm text-red-600" id="email-error">{errors.nhs_id}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="hospital_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Hospital ID
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input value={values.hospital_id} onChange={handleChange} type="text" name="hospital_id" id="hospital_id" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                      {errors.hospital_id &&
                        <p className="mt-2 text-sm text-red-600" id="email-error">{errors.hospital_id}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Gender
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <select
                          id="gender"
                          name="gender"
                          className="capitalize mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          value={capitalize(`${values.gender}`)}
                          onChange={(event) => {
                            if (event.target.value === '-') {
                              handleChange(event);
                            } else {
                              setFieldValue('gender', `${event.target.value}`.toLowerCase(), true);
                            }
                          }}
                        >
                          <option>-</option>
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                      </div>
                      {errors.gender &&
                        <p className="mt-2 text-sm text-red-600" id="gender-error">{errors.gender}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="date_of_birth" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Date of birth
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg relative rounded-md shadow-sm">
                        <input value={values.date_of_birth} onChange={handleChange} type="date" name="date_of_birth" id="date_of_birth" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                      {errors.date_of_birth &&
                        <p className="mt-2 text-sm text-red-600" id="email-error">{errors.date_of_birth}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Country
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <select
                          id="country"
                          name="country"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          autoComplete="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                          <option>Austria</option>
                          <option>Belgium</option>
                          <option>Bulgaria</option>
                          <option>Croatia</option>
                          <option>Cyprus</option>
                          <option>Czech Republic</option>
                          <option>Denmark</option>
                          <option>Estonia</option>
                          <option>Finland</option>
                          <option>France</option>
                          <option>Germany</option>
                          <option>Greece</option>
                          <option>Hungary</option>
                          <option>Ireland</option>
                          <option>Italy</option>
                          <option>Latvia</option>
                          <option>Lithuania</option>
                          <option>Luxembourg</option>
                          <option>Malta</option>
                          <option>Netherlands</option>
                          <option>Poland</option>
                          <option>Portugal</option>
                          <option>Romania</option>
                          <option>Slovakia</option>
                          <option>Spain</option>
                          <option>Sweden</option>
                          <option>United Kingdom</option>
                          <option>Norway</option>
                          <option>Switzerland</option>
                        </select>
                      </div>
                      {errors.country &&
                        <p className="mt-2 text-sm text-red-600" id="country-error">{errors.country}</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <label htmlFor="rollout_plan_start_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Rollout start date
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg relative rounded-md shadow-sm">
                        <input value={values.rollout_plan_start_date} onChange={handleChange} type="date" name="rollout_plan_start_date" id="rollout_plan_start_date" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                      </div>
                      {errors.rollout_plan_start_date &&
                        <p className="mt-2 text-sm text-red-600" id="email-error">{errors.rollout_plan_start_date}</p>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                {initialValues.name == null ? (
                  <>
                    <Link to="/patients">
                      <button type="button" className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        Cancel
                      </button>
                    </Link>
                    <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                      Save
                    </button>
                  </>
                ) : (
                  <button disabled={!isValid} type="button" onClick={() => setModalVisible(true)} className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    Save
                  </button>
                )}
              </div>
            </div>
          </form>
          {modalVisible &&
            <Modal
              title="Update patient"
              content={`This will update the personal information for ${patient.name}.`}
              buttons={
                <>
                  <button type="submit" onClick={submitForm} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:col-start-2 sm:text-sm">
                    Confirm
                  </button>
                  <button type="button" onClick={() => setModalVisible(false)} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                    Cancel
                  </button>
                </>
              }
            />
          }
        </>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
