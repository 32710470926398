import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { getReportRequest, getReportFailure, getReportSuccess } from '../store/reporting';

import { getReport } from '../api/reporting';

const getToken = state => state.account.token;

function* getReportSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getReport, {
    token,
    playphysio_id: action.payload.playphysio_id,
    from: action.payload.from,
    to: action.payload.to,
  });
  yield put(getReportSuccess(response));
}

export default function* reportingSaga() {
  yield takeEvery(getReportRequest.type, getReportSaga);
};
