export async function patients(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/patients`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function getPatient(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/patients/${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function updatePatient(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/patients/${data.playphysio_id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export async function createPatient(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/patients`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
  return response.json();
};
