export async function authenticate(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api-token-auth/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const response_json = await response.json();
  return [response, response_json]
};

export async function me(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/me`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  const response_json = await response.json();
  return [response, response_json];
};

export async function requestPasswordReset(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/password_reset/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  const response_json = await response.json();
  return [response, response_json]
}

export async function resetPassword(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/password_reset/confirm/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: data.token,
      password: data.password,
    })
  });
  const response_json = await response.json();
  return [response, response_json];
}
