import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { signOut as signOutRequest } from '../../store/account';

import Logo from '../../assets/colour-landscape-logo-on-white.png';

import Loading from '../Loading';

const mapStateToProps = state => ({
  user_type: state.account.user_type,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOutRequest()),
});

function Sidebar(props) {
  const { signOut, user_type } = props;
  const location = useLocation();
  const active = 'text-playphysio border-2 border-playphysio';
  const inactive = 'text-gray-700 border-2 border-transparent hover:text-gray-900 hover:bg-gray-50';

  const therapistSidebarContent = (
    <>
      <Link to="/" className="flex items-center flex-shrink-0 px-6">
        <img src={Logo} />
      </Link>
      <div className="h-0 flex-1 flex flex-col overflow-y-auto">
        <nav>
          <div className="px-3 mt-6">
            <div className="space-y-1">
              <Link to="/" className={`${location.pathname === '/' ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                Home
              </Link>
              <Link to="/patients" className={`${location.pathname.includes('/patients') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                Patients
              </Link>
              <Link to="/team" className={`${location.pathname.includes('/team') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Team
              </Link>
            </div>
          </div>
          <div className="px-3 mt-8">
            <Link to="/subscription" className={`${location.pathname.includes('/subscription') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
              <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
              Subscription
            </Link>
          </div>
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
        <a onClick={() => signOut()} className={`${inactive} cursor-pointer group flex-1 flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
          <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
          </svg>
          Sign out
        </a>
      </div>
    </>
  );

  const researchSidebarContent = (
    <>
      <Link to="/" className="flex items-center flex-shrink-0 px-6">
        <img src={Logo} />
      </Link>
      <div className="h-0 flex-1 flex flex-col overflow-y-auto">
        <nav>
          <div className="px-3 mt-6">
            <div className="space-y-1">
              <Link to="/" className={`${location.pathname === '/' ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>
                Home
              </Link>
              <Link to="/reporting" className={`${location.pathname.includes('/reporting') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Reporting
              </Link>
              {/* <Link to="/patients/all/adherence" className={`${location.pathname.includes('/patients/all/adherence') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Treatment
              </Link>
              <Link to="/surveys" className={`${location.pathname.includes('/surveys') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                Surveys
              </Link> */}
              <Link to="/team" className={`${location.pathname.includes('/team') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
                <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Team
              </Link>
            </div>
          </div>
          <div className="px-3 mt-8">
            <Link to="/subscription" className={`${location.pathname.includes('/subscription') ? active : inactive} group flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
              <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
              Subscription
            </Link>
          </div>
        </nav>
      </div>
      <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
        <a onClick={() => signOut()} className={`${inactive} cursor-pointer group flex-1 flex items-center px-2 py-2 text-sm font-medium rounded-md`}>
          <svg className="mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
          </svg>
          Sign out
        </a>
      </div>
    </>
  );

  return (
    <div className="print:hidden h-screen flex overflow-hidden bg-gray-100">
      {props.isOpen &&
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40">
            <div className="fixed inset-0" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button onClick={() => props.setIsOpen(!props.isOpen)} className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Close sidebar</span>
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              {user_type === 'research' ? researchSidebarContent : therapistSidebarContent}
            </div>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
            </div>
          </div>
        </div>
      }
      <div className="hidden lg:flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
        {user_type === 'research' ? researchSidebarContent : therapistSidebarContent}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
