import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { UsersIcon, UserGroupIcon } from '@heroicons/react/outline';

import Dashboard from '../../layout/dashboard';
import Loading from '../../Loading';

import { getPatients } from '../../../store/patients';
import { getTeamRequest } from '../../../store/team';

const mapStateToProps = ({ account, patients, team }) => ({
  username: account.username,
  user_type: account.user_type,
  subscription_name: account.subscription_name,
  patients,
  team,
});

const mapDispatchToProps = dispatch => ({
  getPatients: () => dispatch(getPatients()),
  getTeam: () => dispatch(getTeamRequest()),
});

function Home({ username, subscription_name, getPatients, patients, team, getTeam }) {
  useEffect(() => {
    getPatients();
    getTeam();
  }, []);

  const StatCard = React.memo(({ title, text, value, link, icon: IconComponent, ...props }) => (
    <div {...props} className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
      <div className="absolute bg-playphysio rounded-md p-3">
        <IconComponent className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <p className="ml-16 text-sm font-medium text-gray-500 truncate">{title}</p>
      <div className="ml-16 pb-6 flex items-baseline sm:pb-7">
        {value}
        <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div className="text-sm">
            <Link to={link} className="font-medium text-indigo-600 hover:text-indigo-500">
              <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-playphysio hover:ring-2 hover:ring-offset-2 hover:ring-purple-500">
                {text}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <Dashboard title={subscription_name} buttons={!username ? <Loading /> : <p>Signed in as {username}</p>}>
      <div className="p-4">
        {!username ? (
          <Loading />
        ) : (
          <div className="grid grid-cols-1 gap-5 items-center sm:grid-cols-2 lg:grid-cols-3">
            <StatCard
              data-testid="total-patients-count"
              title="Total patients"
              text="View patients"
              value={patients.list.loading ? <Loading /> : <p className="text-2xl font-semibold text-gray-900">{patients.list.items.length}</p>}
              link="/patients"
              icon={UsersIcon}
            />
            <StatCard 
              data-testid="total-users-count"
              title="Total dashboard users"
              text="View team"
              value={team.list.loading ? <Loading /> : <p className="text-2xl font-semibold text-gray-900">{team.list.items.length}</p>}
              link="/team"
              icon={UserGroupIcon}
            />
          </div>
        )}
      </div>
    </Dashboard>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
