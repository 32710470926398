import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import {
  getRemindersRequest,
  getRemindersFailure,
  getRemindersSuccess,
  createReminderRequest,
  createReminderFailure,
  createReminderSuccess,
  deleteReminderRequest,
  deleteReminderFailure,
  deleteReminderSuccess
} from '../store/reminders';

import { getReminders, createReminder, deleteReminder } from '../api/reminders';

const getToken = state => state.account.token;

function* getRemindersSaga(action) {
  const token = yield select(getToken);
  try {
    const response = yield call(getReminders, {
      token,
      playphysio_id: action.payload,
    });
    yield put(getRemindersSuccess(response));
  } catch (exc) {
    console.error(exc);
  }
}

function* createReminderSaga(action) {
  const token = yield select(getToken);
  const response = yield call(createReminder, {
    token,
    ...action.payload,
  });
  yield put(createReminderSuccess(response));
}

function* deleteReminderSaga(action) {
  const token = yield select(getToken);
  yield call(deleteReminder, {
    token,
    ...action.payload,
  });
  yield put(deleteReminderSuccess(action.payload.playphysio_id));
}

export default function* remindersSaga() {
  yield takeEvery([getRemindersRequest.type, deleteReminderSuccess.type], getRemindersSaga);
  yield takeEvery(createReminderRequest.type, createReminderSaga);
  yield takeEvery(deleteReminderRequest.type, deleteReminderSaga);
};
