import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const mapStateToProps = state => ({
  surveys: state.patients.view.surveys,
});

const mapDispatchToProps = dispatch => ({
});

function QuestionnaireCard(props) {
  const { surveys } = props;
  const { id } = useParams();
  if (surveys === []) {
    return <></>;
  }
  return (
    <div data-testid="questionnaire-card" className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6 flex">
        <div className="flex-1">
          Questionnaires
        </div>
      </div>
      <div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Completion date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Link
              </th>
            </tr>
          </thead>
          <tbody>
            {surveys.map(survey => {
              return (
                <tr className="bg-white">
                  {survey.completed ? (
                    <Link to={`/surveys/${survey.uuid}`}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 font-bold underline">{survey.name || 'Unknown'}</td>
                    </Link>
                  ) : (
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{survey.name || 'Unknown'}</td>
                  )}
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {survey.completed ? (
                      <span className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize`}>
                        {new Date(survey.completion_date * 1000).toLocaleDateString()}
                      </span>
                    ) : (
                      <span className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize`}>
                        Not complete
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium text-gray-900">
                    {survey.completed ? (
                      <span>N/A</span>
                    ) : (
                      <a href={survey.survey_link} target="_blank">{survey.survey_link}</a>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div >
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireCard);
