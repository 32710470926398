import { createAction } from '@reduxjs/toolkit';

export const getPracticeBlowsRequest = createAction('GET_PRACTICE_BLOWS_REQUEST');
export const getPracticeBlowsFailure = createAction('GET_PRACTICE_BLOWS_FAILURE');
export const getPracticeBlowsSuccess = createAction('GET_PRACTICE_BLOWS_SUCCESS');

export const updateBlowDurationRequest = createAction('UPDATE_BLOW_DURATION_REQUEST');
export const updateBlowDurationFailure = createAction('UPDATE_BLOW_DURATION_FAILURE');
export const updateBlowDurationSuccess = createAction('UPDATE_BLOW_DURATION_SUCCESS');

export const updatePracticeStateRequest = createAction('UPDATE_PRACTICE_STATE_REQUEST');
export const updatePracticeStateFailure = createAction('UPDATE_PRACTICE_STATE_FAILURE');
export const updatePracticeStateSuccess = createAction('UPDATE_PRACTICE_STATE_SUCCESS');

const initialState = {
  loading: false,
  error: null,
  data: {
    blows: []
  },
};

export default function practice(state = initialState, action) {
  switch (action.type) {
    case getPracticeBlowsRequest.type:
      return {
        ...initialState
      };
    case getPracticeBlowsFailure.type:
      return {
        ...initialState,
        error: 'Failed to load',
      };
    case getPracticeBlowsSuccess.type:
      return {
        ...state,
        loading: false,
        data: {
          blows: action.payload,
        },
        error: null,
      };
    default:
      return state;
  }
};
