import React, { useEffect, useState } from 'react';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import faker from '@faker-js/faker';

import { dateToShortString, mergeDaysForSessionStartEnd, generateRandomColor } from './utils';

export default function AdherencePercentage(props) {
    const { data, loading } = props;
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);

    const colors = [
        '#812C7C',
        '#22C55E',
        '#EAB308',
        '#06B6D4',
        '#6366F1',
        '#D946EF',
        '#EC4899',
        '#F43F5E',
        '#84CC16',
        '#3B82F6',
    ];

    useEffect(() => {
        if (data.length > 0) {
            const merged_days = mergeDaysForSessionStartEnd(data);

            const labels = Object.keys(merged_days).sort((a, b) => new Date(a) - new Date(b)).map(d => dateToShortString(d));
            setLabels(labels);

            let datasets = [];

            let blah = {};

            Object.entries(merged_days).sort((a, b) => new Date(a[0]) - new Date(b[0])).forEach(itm => {
                itm[1].forEach((val, idx) => {
                    if (!blah[idx]) {
                        blah[idx] = [];
                    }
                    blah[idx].push(val);
                });
            });

            let starts = [];
            let ends = [];

            Object.entries(blah).sort((a, b) => b < a).map(itm => {
                itm[1].forEach((val, idx) => {
                    starts.push({ 'x': idx, 'y': val['start'] });
                    ends.push({ 'x': idx, 'y': val['end'] });
                });
            });

            datasets.push(
                {
                    label: 'Start',
                    data: starts,
                    backgroundColor: colors.pop() || generateRandomColor(),
                }
            )

            datasets.push(
                {
                    label: 'End',
                    data: ends,
                    backgroundColor: colors.pop() || generateRandomColor(),
                }
            )

            setDatasets(datasets);

        }
    }, [data]);

    const graphOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Session Start/End Times',
            },
            tooltip: {
                callbacks: {
                    label: (item) => {
                        return (
                            `${item.dataset.label}: ${labels[item.raw.x]} ${item.raw.y.replace('.', ':')}`
                        );
                    },
                }
            },
        },
        scales: {
            x: {
                ticks: {
                    callback: function (val, index) {
                        return labels[parseInt(this.getLabelForValue(val))];
                    },
                },
            },
            y: {
                min: 0,
                max: 24,
            },
        },
    };

    const graphData = {
        labels,
        datasets: datasets,
        // datasets: [
        //     {
        //         label: 'tese',
        //         data: Array.from({ length: 100 }, () => ({
        //             x: faker.datatype.number({ min: -100, max: 100 }),
        //             y: faker.datatype.number({ min: -100, max: 100 }),
        //         })),
        //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //     },
        //     {
        //         label: 'Completed',
        //         data: Array.from({ length: 100 }, () => ({
        //             x: faker.datatype.number({ min: -100, max: 100 }),
        //             y: faker.datatype.number({ min: -100, max: 100 }),
        //         })),
        //         backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //     },
        // ],
    };

    return (
        <Chart type="scatter" options={graphOptions} data={graphData} />
    );
}