import React from 'react';

import Dashboard from '../../../layout/dashboard';
import TeamUserInfo from '../../../TeamUserInfo';

export default function New() {
  return (
    <Dashboard title="New user">
      <div className="m-4 bg-white shadow rounded-lg py-6 px-6">
        <TeamUserInfo />
      </div>
    </Dashboard>
  );
};
