import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  getLatestSessionPlanRequest,
  getLatestSessionPlanFailure,
  getLatestSessionPlanSuccess,
  createSessionPlanRequest,
  createSessionPlanFailure,
  createSessionPlanSuccess
} from '../store/session_plans';

import { updateBlowDurationSuccess } from '../store/practice';

import { getLatestSessionPlan, createSessionPlan } from '../api/session_plans';
import { createTherapyDevice } from '../api/devices';

const getToken = state => state.account.token;

function* getLatestSessionPlanSaga(action) {
  const token = yield select(getToken);
  const [response, json] = yield call(getLatestSessionPlan, {
    token,
    playphysio_id: action.payload,
  });
  if (response.status !== 200) {
    yield put(getLatestSessionPlanFailure(404));
  } else {
    yield put(getLatestSessionPlanSuccess(json));
  }
}

const therapy_devices = {
  'Aerobika': 1,
  'Acapella': 2,
  'Shaker': 3,
  'Pari': 4,
  'Flutter': 5
};

function* createSessionPlanSaga(action) {
  const token = yield select(getToken);
  let response = yield call(createTherapyDevice, {
    token,
    playphysio_id: action.payload.playphysio_id,
    device_id: therapy_devices[action.payload.therapy_device],
    device_setting: action.payload.therapy_device_setting,
  });
  let json;
  [response, json] = yield call(createSessionPlan, {
    token,
    ...action.payload
  });
  if (response.status !== 201) {
    yield put(createSessionPlanFailure(json));
  } else {
    yield put(createSessionPlanSuccess(json));
    yield put(push(`/patients/${action.payload.playphysio_id}/overview`))
  }
}

export default function* sessionPlanSaga() {
  yield takeEvery([getLatestSessionPlanRequest.type, updateBlowDurationSuccess.type], getLatestSessionPlanSaga);
  yield takeEvery(createSessionPlanRequest.type, createSessionPlanSaga);
};
