import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FlexibleWidthXYPlot, LineSeries, XAxis, YAxis } from 'react-vis';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

function AdherenceMonitoringCard(props) {
  const { id } = useParams();
  const data = [
    { x: 0, y: 1 },
    { x: 1, y: 2 },
    { x: 2, y: 3 },
    { x: 4, y: 6 },
    { x: 5, y: 3 },
    { x: 6, y: 2 },
    { x: 7, y: 1 }
  ];
  return (
    <div data-testid="adherence-monitoring-card" className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div className="px-4 py-5 sm:px-6 flex">
        <div className="flex-1">
          Adherence monitoring
        </div>
        <div className="flex space-x-4">
          <Link to={`/patients/${id}/graphs`}>
            <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              Graphs
            </button>
          </Link>
          <Link to={`/patients/${id}/adherence`}>
            <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              Data
            </button>
          </Link>
        </div>
      </div>
      <div className="flex">
        {/* <FlexibleWidthXYPlot height={300}>
          <XAxis />
          <YAxis />
          <LineSeries data={data} />
        </FlexibleWidthXYPlot> */}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdherenceMonitoringCard);
