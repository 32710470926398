export async function getPracticeBlows(data = {}) {
  let url = `${process.env.REACT_APP_BASE_URL}/practice?playphysio_id=${data.playphysio_id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function updateDuration(data = {}) {
  let url = `${process.env.REACT_APP_BASE_URL}/practice/duration?playphysio_id=${data.playphysio_id}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      duration: data.duration,
    }),
  });
  return response.json();
}

export async function updateState(data = {}) {
  let url = `${process.env.REACT_APP_BASE_URL}/practice/state?playphysio_id=${data.playphysio_id}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      state: data.state,
    }),
  });
  return response.json();
}