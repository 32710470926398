import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Dashboard from '../../../layout/dashboard';

import { researcherGetSurveysRequest } from '../../../../store/surveys';

const mapStateToProps = (state) => ({
  account: state.account,
  surveys: state.surveys,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveys: () => dispatch(researcherGetSurveysRequest()),
});

function Surveys(props) {
  const [searchTerm, setSearchTerm] = useState(null);
  const { account, getSurveys, surveys } = props;
  useEffect(() => {
    getSurveys();
  }, []);
  const rows = [];
  surveys.items.filter(survey => {
    if (searchTerm === null) {
      return survey;
    }
    if (survey.name && survey.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return survey;
    }
    if (survey.secret_word && survey.secret_word.toLowerCase().includes(searchTerm.toLowerCase())) {
      return survey;
    }
  }).map(survey => {
    rows.push(
      <tr key={survey.uuid} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <div className="text-sm font-medium text-gray-900">
            {survey.playphysio_id}
          </div>
          <div className="text-sm text-gray-500">
            {survey.secret_word}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {survey.name}
        </td>
        <td className="px-6 py-4 text-sm font-medium text-gray-900">
          <a href={survey.survey_link}>{survey.survey_link}</a>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {survey.completed ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          )}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium sm:px-6 lg:px-8">
          <Link to={`/surveys/${survey.uuid}`} className="text-indigo-600 hover:text-indigo-900">
            <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              View
            </button>
          </Link>
        </td>
      </tr>
    )
  });
  return (
    <Dashboard title="Surveys"
      buttons={
        <a href={`${process.env.REACT_APP_BASE_URL}/surveys/all/${account.subscription}/csv`}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            Download all as CSV
            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </button>
        </a>
      }
    >
      <div className="flex-1 flex flex-col">
        <div className="border-b border-gray-200 px-4">
          <label htmlFor="search_field" className="sr-only">Search surveys</label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input onChange={(event) => setSearchTerm(event.target.value)} id="search_field" className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search surveys" type="search" name="search" />
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Patient
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Survey
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Link
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Completed
                    </th>
                    <th scope="col" className="relative px-6 py-3 ">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Surveys);
