import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';

const labels = [''];

export default function AdherencePercentage(props) {
    const { data, loading, maintainAspectRatio } = props;

    const graphOptions = {
        responsive: true,
        maintainAspectRatio: maintainAspectRatio,
        plugins: {
            datalabels: {
                color: '#FFF',
                font: {
                    weight: 'bold',
                },
                formatter: function (value, context) {
                    return `${value}%`;
                }
            },
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: 'Adherence Percentage',
            },
            tooltip: {
                callbacks: {
                    label: (item) => `${item.dataset.label}: ${item.formattedValue}%`,
                }
            },
            dataLabels: {
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                min: 0,
                max: 100,
                ticks: {
                    mirror: false,
                },
            },
        },
    };

    let adherence = [];
    data.forEach(item => {
        adherence.push(Math.round(Math.min((item.total_complete_blows / Math.max(item.total_required_blows, 1)) * 100, 100)))
    })

    let missed;
    let completed;

    if (adherence.length < 1) {
        missed = 100;
        completed = 0;
    } else {
        completed = Math.round(adherence.reduce((a, b) => a + b, 0) / adherence.length);
        missed = 100 - completed;
    }

    const graphData = {
        labels,
        datasets: [
            {
                label: 'Completed',
                data: [completed],
                backgroundColor: '#22C55E',
            },
            {
                label: 'Missing',
                data: [missed],
                backgroundColor: '#EF4444',
            },
        ],
    };

    return (
        <Chart type="bar" plugins={[ChartDataLabels]} options={graphOptions} data={graphData} />
    );
}