import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Switch } from '@headlessui/react';
import { connect } from 'react-redux';
import { RefreshIcon } from '@heroicons/react/solid';

import Dashboard from '../../../layout/dashboard';

import { getPatientRequest } from '../../../../store/patients';
import { getLatestSessionPlanRequest } from '../../../../store/session_plans';
import { getPracticeBlowsRequest, updateBlowDurationRequest, updatePracticeStateRequest } from '../../../../store/practice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const mapStateToProps = state => ({
  blows: state.practice.data.blows,
  session_plan: state.session_plans.view,
  patient: state.patients.view,
});

const mapDispatchToProps = dispatch => ({
  getPatient: (playphysio_id) => dispatch(getPatientRequest(playphysio_id)),
  getLatestSessionPlan: (playphysio_id) => dispatch(getLatestSessionPlanRequest(playphysio_id)),
  getPracticeBlows: (id) => dispatch(getPracticeBlowsRequest(id)),
  updateBlowDuration: (id, duration) => dispatch(updateBlowDurationRequest({
    'playphysio_id': id,
    'duration': duration,
  })),
  updatePracticeState: (id, state) => dispatch(updatePracticeStateRequest({
    'playphysio_id': id,
    'state': state,
  })),
});

function PracticeBlows(props) {
  const { getPracticeBlows, blows, session_plan, updateBlowDuration, updatePracticeState, getPatient, getLatestSessionPlan, patient } = props;
  const { id } = useParams();
  const [enabled, setEnabled] = useState(patient.practice);
  const [blowDuration, setBlowDuration] = useState(session_plan.min_blow_time);

  useEffect(() => {
    getPatient(id);
    getLatestSessionPlan(id);
    getPracticeBlows(id);
  }, []);

  if (patient.practice !== enabled) {
    setEnabled(patient.practice);
  }

  const sorted_blows = blows.sort((a, b) => a.date < b.date ? 1 : -1)

  const rows = [];
  sorted_blows.map((blow, blowIdx) => {
    rows.push(
      <tr key={`blow-${blowIdx}`} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {new Date(blow.date * 1000).toLocaleDateString()} {new Date(blow.date * 1000).toLocaleTimeString()}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {blow.number}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {Math.trunc(blow.duration * 1000)}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {blow.prescription}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${Math.trunc(blow.duration * 1000) >= blow.prescription ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {Math.trunc(blow.duration * 1000) >= blow.prescription ? 'Good' : 'No good'}
          </span>
        </td>
      </tr>
    );
  });

  return (
    <Dashboard title="Practice Blows">
      <div className="mx-12 mt-8 grid grid-cols-3 gap-4 text-center">
        <div>
          <div>
            <b>1. Ask your patient to:</b>
            <ul className="mt-4 space-y-2">
              <li>- Start the Playphysio App and tap the face avatar in the centre of the screen.</li>
              <li>- Tap the 'Practice Blow' button.</li>
              <li>- Please complete three to five practice blows.</li>
              <li>- Now tap the 'Share' button.</li>
            </ul>
          </div>
          <div className="mt-8"><b>2. Click your browser refresh button</b> to see your patients blows appear in the table below.</div>
        </div>
        <div>
          <div>
            <div><b>3. Review the patients blow performance</b> in the table below:</div>
            <ul className="mt-4 space-y-2">
              <li>- You may adjust the current blow duration by typing a new value into the 'Set new duration' box.</li>
              <li>- Click the 'Update' button to save your changes.</li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <div><b>If you change the blow duration value</b> ask the patient to:</div>
            <ul className="mt-4 space-y-2">
              <li>- Tap on the 'Update' button on the practice screen.</li>
              <li>- Read back the blow duration number in the App - it should match the Current Blow Duration you have set.</li>
            </ul>
          </div>
          <div className="mt-8">
            <div><b>Now repeat step 1 and 2.</b> You can review another set of practice blows from the patient until you are happy the setting is appropriate.</div>
          </div>
        </div>
      </div>
      <div className="mt-12 mx-12 flex flex-col xl:flex-row">
        <div className="flex-1 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6 flex">
            <div className="flex-1">
              Practice Blows
            </div>
            <div className="flex flex-shrink items-center justify-items-start">
              <Switch
                checked={enabled}
                onChange={val => {
                  setEnabled(val);
                  updatePracticeState(id, val);
                }}
                className={classNames(
                  enabled ? 'bg-indigo-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <span className={`ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${enabled ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                {enabled ? 'Practice mode on' : 'Practice mode off'}
              </span>
              <button
                type="button"
                onClick={() => getPracticeBlows(id)}
                className="ml-4 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Refresh
                <RefreshIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Datetime
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Blow Number
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Duration
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Prescription
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-4 xl:mt-0 xl:ml-4 flex-initial bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 flex flex-col">
          <div className="px-12 py-5 flex">
            Prescribed Blow Duration
          </div>
          <div className="bg-gray-50 px-12 text-center divide-y divide-gray-200 flex-1">
            <div className="pt-4 pb-4"><b>Prescribed blow duration:</b> {session_plan.min_blow_time}</div>
            <div className="py-0 xl:py-4 flex-1">
              <div>
                <label htmlFor="new_duration" className="block text-sm font-medium text-gray-700 text-left">
                  Set a new duration
                </label>
                <div className="mt-1">
                  <input
                    onChange={event => setBlowDuration(event.target.value)}
                    type="number"
                    name="new_duration"
                    id="new_duration"
                    className="shadow-sm focus:ring-purple-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>
              <button
                onClick={() => updateBlowDuration(id, blowDuration)}
                type="button"
                className="mt-4 inline-flex items-start px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(PracticeBlows);
