import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Modal from '../../Modal';

import { getPatientDevicesRequest, pauseDeviceRequest } from '../../../store/patients';

const mapStateToProps = state => ({
  devices: state.patients.view.devices,
});

const mapDispatchToProps = dispatch => ({
  getDevices: (id) => dispatch(getPatientDevicesRequest(id)),
  pauseDevice: (id, serial_number) => dispatch(pauseDeviceRequest({
    playphysio_id: id,
    serial_number,
  })),
});

function DevicesCard(props) {
  const { id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [device, setDevice] = useState(null);
  const { getDevices, devices, pauseDevice } = props;
  useEffect(() => {
    getDevices(id);
  }, [getDevices]);
  const rows = [];
  devices.sort((a, b) => (a.status == 'live' && b.status != 'live') ? 0 : 1).map((device, deviceIdx) => {
    rows.push(
      <tr key={`device-${deviceIdx}`} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {device.serial_number}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className={`px-2 in line-flex text-xs leading-5 font-semibold rounded-full ${device.status == 'live' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} capitalize`}>
            {device.status}
          </span>
        </td>
        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
          {device.status === 'live' &&
            <button onClick={() => {
              setDevice(device.serial_number);
              setModalVisible(true);
            }} type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              Pause
            </button>
          }
        </td>
      </tr>
    );
  });
  return (
    <div data-testid="devices-card">
      <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div className="px-4 py-5 sm:px-6 flex">
          <div className="flex-1">
            Physiopal devices
          </div>
          <div className="flex-shrink">
            <Link to="/physiopal/find" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                Assign new device
            </Link>
            </div>
        </div>
        <div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Serial
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
      {modalVisible &&
        <Modal
          title={'Are you sure?'}
          content={`This will pause the patients physiopal device. They will be unable to continue recording treatment until another Physiopal is activated.`}
          buttons={
            <>
              <button onClick={() => {
                setModalVisible(false);
                pauseDevice(id, device);
               }} type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm">
                Confirm
                </button>
              <button onClick={() => setModalVisible(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                Cancel
                </button>
            </>
          }
        />
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesCard);
