import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  getSubscriptionRequest,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  updateSubscriptionRequest,
  updateSubscriptionFailure,
  updateSubscriptionSuccess
} from '../store/subscription';

import { getSubscription, updateSubscription } from '../api/subscription';

const getToken = state => state.account.token;

function* getSubscriptionSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getSubscription, {
    token,
  });
  yield put(getSubscriptionSuccess(response));
}

function* updateSubscriptionSaga(action) {
  const token = yield select(getToken);
  const response = yield call(updateSubscription, {
    token,
    ...action.payload,
  });
  yield put(updateSubscriptionSuccess(response));
}

export default function* subscriptionSaga() {
  yield takeEvery(getSubscriptionRequest.type, getSubscriptionSaga);
  yield takeEvery(updateSubscriptionRequest.type, updateSubscriptionSaga);
};
