import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { getAppointmentsRequest, createAppointmentRequest, deleteAppointmentRequest } from '../../../store/appointments';

const mapStateToProps = state => ({
    appointments: state.appointments.appointments,
});

const mapDispatchToProps = dispatch => ({
    getAppointments: (id) => dispatch(getAppointmentsRequest(id)),
    createAppointment: (id, datetime) => dispatch(createAppointmentRequest({
        playphysio_id: id,
        datetime,
    })),
    deleteAppointment: (id, datetime) => dispatch(deleteAppointmentRequest({
        playphysio_id: id,
        datetime,
    })),
});

function AppointmentsCard(props) {
    const { id } = useParams();
    const { appointments, getAppointments, createAppointment, deleteAppointment } = props;
    const [addingAppointment, setAddingAppointment] = useState(false);
    useEffect(() => {
        getAppointments(id);
    }, []);
    const rows = [];
    appointments.sort((a, b) => a.datetime < b.datetime ? 1 : -1).map((appointment, appointmentIdx) => {
        rows.push(
            <tr key={`appointment-${appointmentIdx}`} className="bg-white">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {new Date(appointment.datetime * 1000).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                    <button onClick={() => {
                        deleteAppointment(id, appointment.datetime);
                    }} type="submit" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        Delete
                    </button>
                </td>
            </tr>
        );
    });
    return (
        <div data-testid="appointments-card" className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
            <div className="px-4 py-5 sm:px-6 flex">
                <div className="flex-1">
                    Appointments
                </div>
                <div className="flex-shrink">
                    <button onClick={() => setAddingAppointment(!addingAppointment)} className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                        {addingAppointment ? 'Cancel' : 'Add appointment'}
                    </button>
                </div>
            </div>
            <div>
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                        {addingAppointment &&
                            <Formik
                                validateOnBlur
                                initialValues={{}}
                                onSubmit={(values) => {
                                    createAppointment(id, values.datetime);
                                    setAddingAppointment(false);
                                }}
                            >
                                {({
                                    initialValues,
                                    values,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    submitForm
                                }) => (
                                    <tr className="bg-white">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            <input value={values.datetime} onChange={handleChange} onBlur={handleBlur} type="date" name="datetime" id="datetime" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                                        </td>
                                        <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                            <button onClick={submitForm} type="submit" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                                Create
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </Formik>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsCard);
