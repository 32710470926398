import { createAction } from '@reduxjs/toolkit';

export const getTeamRequest = createAction('GET_TEAM_REQUEST');
export const getTeamFailure = createAction('GET_TEAM_FAILURE');
export const getTeamSuccess = createAction('GET_TEAM_SUCCESS');

export const getTeamMemberRequest = createAction('GET_TEAM_MEMBER_REQUEST');
export const getTeamMemberFailure = createAction('GET_TEAM_MEMBER_FAILURE');
export const getTeamMemberSuccess = createAction('GET_TEAM_MEMBER_SUCCESS');

export const deleteTeamMemberRequest = createAction('DELETE_TEAM_MEMBER_REQUEST');
export const deleteTeamMemberFailure = createAction('DELETE_TEAM_MEMBER_FAILURE');
export const deleteTeamMemberSuccess = createAction('DELETE_TEAM_MEMBER_SUCCESS');

export const updateTeamUserRequest = createAction('UPDATE_USER_REQUEST');
export const updateTeamUserFailure = createAction('UPDATE_USER_FAILURE');
export const updateTeamUserSuccess = createAction('UPDATE_USER_SUCCESS');

export const createTeamUserRequest = createAction('CREATE_USER_REQUEST');
export const createTeamUserFailure = createAction('CREATE_USER_FAILURE');
export const createTeamUserSuccess = createAction('CREATE_USER_SUCCESS');

const initialState = {
  list: {
    items: [],
    loading: false,
  },
  view: {
    error: null,
    loading: false,
  },
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case getTeamRequest.type:
      return {
        ...state,
        list: {
          items: [],
          loading: true,
        },
      };
    case getTeamFailure.type:
      return {
        ...state,
        list: {
          items: [],
          loading: false,
        },
      };
    case getTeamSuccess.type:
      return {
        ...state,
        list: {
          items: action.payload,
          loading: false,
        },
      };
    case getTeamMemberRequest.type:
      return {
        ...state,
        view: {
          loading: true,
        },
      };
    case getTeamMemberFailure.type:
      return {
        ...state,
        view: {
          loading: false,
        },
      };
    case getTeamMemberSuccess.type:
      return {
        ...state,
        view: {
          loading: false,
          ...action.payload,
        },
      };
    case deleteTeamMemberFailure.type:
      return {
        ...state,
        view: {
          ...state.view,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
