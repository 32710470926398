import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { resetPasswordConfirm } from '../../../../store/account';

const queryString = require('query-string');

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (token, password) => dispatch(resetPasswordConfirm({
    token,
    password,
  })),
});

function ResetPassword(props) {
  const { resetPassword } = props;
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const token = parsed.token;
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/">
          <img className="mx-auto h-32 w-auto" src="https://play.physio/wp-content/uploads/2017/02/larger-web-logo-r.png" alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-playphysio">
            Password reset
          </h2>
        </Link>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Formik
            initialValues={{ password: '' }}
            onSubmit={(values) => {
              resetPassword(token, values.password);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting
            }) => (
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  New password
            </label>
                <div className="mt-1">
                  <input id="password" name="password" onChange={handleChange} type="password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
              <div>
                <button type="submit" disabled={isSubmitting} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-playphysio hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                  Reset password
            </button>
              </div>
            </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
