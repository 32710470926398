import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  getLatestTherapyDeviceRequest,
  getLatestTherapyDeviceFailure,
  getLatestTherapyDeviceSuccess,
  findPhysiopalDeviceRequest,
  findPhysiopalDeviceFailure,
  findPhysiopalDeviceSuccess,
  assignPhysiopalToPatientRequest,
  assignPhysiopalToPatientFailure
} from '../store/devices';

import { getLatestTherapyDevice, findPhysiopalDevice, assignPhysiopalToPatient } from '../api/devices';

const getToken = state => state.account.token;

function* getLatestTherapyDeviceSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getLatestTherapyDevice, {
    token,
    playphysio_id: action.payload,
  });
  return response.json();
}

function* findPhysiopalDeviceSaga(action) {
  const token = yield select(getToken);
  const [response, json] = yield call(findPhysiopalDevice, {
    token,
    serial_number: action.payload,
  });
  if (response.status === 200) {
    yield put(findPhysiopalDeviceSuccess(json));
  } else {
    yield put(findPhysiopalDeviceFailure(json));
  }
}

function* assignPhysiopalToPatientSaga(action) {
  const token = yield select(getToken);
  const [response, json] = yield call(assignPhysiopalToPatient, {
    token,
    playphysio_id: action.payload.playphysio_id,
    serial_number: action.payload.serial_number,
  });
  if (response.status === 200) {
    yield put(push(`/patients/${action.payload.playphysio_id}/overview`));
  } else {
    yield put(assignPhysiopalToPatientFailure(json));
  }
}

export default function* devicesSaga() {
  yield takeEvery(getLatestTherapyDeviceRequest.type, getLatestTherapyDeviceSaga);
  yield takeEvery(findPhysiopalDeviceRequest.type, findPhysiopalDeviceSaga);
  yield takeEvery(assignPhysiopalToPatientRequest.type, assignPhysiopalToPatientSaga);
};
