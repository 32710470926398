export async function getReminders(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/reminders?playphysio_id=${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

const REMINDER_TYPE_MAP = {
  'Time offset': 'time',
  'Blowset completion': 'blowset',
  'Position completion': 'position',
  'Session completion': 'session'
};

export async function createReminder(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/reminders?playphysio_id=${data.playphysio_id}`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'reminders': [
        {
          reminder_type: REMINDER_TYPE_MAP[data.reminder.type],
          time_offset: data.reminder.offset,
          text: data.reminder.text,
        }
      ],
    })
  });
  return response.json();
};

export async function deleteReminder(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/reminders?playphysio_id=${data.playphysio_id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      uuid: data.uuid,
    })
  });
};
