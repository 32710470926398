import { call, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  checkIfSignedInRequest,
  signIn,
  signInFailure,
  signInSuccess,
  signOut,
  resetPasswordRequest,
  resetPasswordConfirm,
  meSuccess
} from '../store/account';
import { authenticate, me, requestPasswordReset, resetPassword } from '../api/auth';

export const getToken = state => state.account.token;

export function* redirectToRoot() {
  yield put(push('/'));
}

export function* redirectToSignIn() {
  yield put(push('/signin'));
}

export function* redirectToError() {
  yield put(push('/error'));
}

export function* redirectToSentResetPassword() {
  yield put(push('/password/reset/sent'));
}

export function* checkIfSignedInSaga() {
  const token = yield select(getToken);
  const [response, json] = yield call(me, { token });
  if (response.status !== 200) {
    yield put(signOut());
    yield call(redirectToSignIn);
  } else {
    yield put(meSuccess(json));
  }
}

export function* signInSaga(action) {
  const { username, password } = action.payload;
  const [response, { token }] = yield call(authenticate, { username, password });
  if (response.status === 200) {
    yield put(signInSuccess({ token }));
  } else {
    yield put(signInFailure({ error: 'Invalid email/password' }));
  }
}

export function* signInSuccessSaga() {
  const token = yield select(getToken);
  const [response, json] = yield call(me, { token });
  yield put(meSuccess(json));
  yield call(redirectToRoot);
}

export function* requestPasswordResetSaga(action) {
  const { email } = action.payload;
  const [response] = yield call(requestPasswordReset, { email });
  if (response.status === 200) {
    yield call(redirectToSentResetPassword);
  } else {
    yield call(redirectToError);
  }
}

export function* resetPasswordSaga(action) {
  const { token, password } = action.payload;
  const [response] = yield call(resetPassword, { token, password });
  if (response.status === 200) {
    yield call(redirectToSignIn);
  } else {
    yield call(redirectToError);
  }
}

export function* signOutSaga() {
  yield call(redirectToSignIn);
}

export default function* accountSaga() {
  yield takeEvery(checkIfSignedInRequest.type, checkIfSignedInSaga);
  yield takeEvery(signIn.type, signInSaga);
  yield takeEvery(signInSuccess.type, signInSuccessSaga);
  yield takeEvery(resetPasswordRequest.type, requestPasswordResetSaga);
  yield takeEvery(resetPasswordConfirm.type, resetPasswordSaga);
  yield takeEvery(signOut.type, signOutSaga);
}
