export async function getConsentObjects(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/consent?playphysio_id=${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};
