import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Dashboard from '../../../layout/dashboard';

import { getTeamRequest } from '../../../../store/team';

const mapStateToProps = (state) => ({
  team: state.team.list.items,
});

const mapDispatchToProps = (dispatch) => ({
  getTeam: () => dispatch(getTeamRequest()),
});

const USER_TYPES = {
  'therapist': 'Therapist',
  'research': 'Researcher',
};

function TeamList(props) {
  const [searchTerm, setSearchTerm] = useState(null);
  const { getTeam, team } = props;
  useEffect(() => {
    getTeam();
  }, [getTeam]);
  const rows = [];
  team.filter(person => {
    if (searchTerm === null) {
      return person;
    }
    if (person.name && person.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return person;
    }
    if (person.username && person.username.toLowerCase().includes(searchTerm.toLowerCase())) {
      return person;
    }
  }).map(person => {
    rows.push(
      <tr key={person.name} className="bg-white">
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {person.username}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {USER_TYPES[person.user_type]}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          {person.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium sm:px-6 lg:px-8">
          <Link to={`/team/${person.user}`} className="text-indigo-600 hover:text-indigo-900">
            <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              View
            </button>
          </Link>
        </td>
      </tr>
    )
  });
  return (
    <Dashboard title="Team" buttons={
      <Link to="/team/new">
        <button type="button" className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:order-1 sm:ml-3">
          New User
        </button>
      </Link>
    }>
      <div className="flex-1 flex flex-col">
        <div className="border-b border-gray-200 bg-white">
          <label htmlFor="search_field" className="sr-only">Search users</label>
          <div className="px-4 relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 flex items-center pointer-events-none">
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input onChange={(event) => setSearchTerm(event.target.value)} id="search_field" className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search users..." type="search" name="search" />
          </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Username
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" className="relative px-6 py-3 ">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamList);
