import { createAction } from '@reduxjs/toolkit';

export const getLatestSessionPlanRequest = createAction('GET_LATEST_SESSION_PLAN_REQUEST');
export const getLatestSessionPlanFailure = createAction('GET_LATEST_SESSION_PLAN_FAILURE');
export const getLatestSessionPlanSuccess = createAction('GET_LATEST_SESSION_PLAN_SUCCESS');

export const createSessionPlanRequest = createAction('CREATE_SESSION_PLAN_REQUEST');
export const createSessionPlanFailure = createAction('CREATE_SESSION_PLAN_FAILURE');
export const createSessionPlanSuccess = createAction('CREATE_SESSION_PLAN_SUCCESS');

const initialState = {
  view: {
    loading: false,
    error: null,
  },
};

export default function session_plans(state = initialState, action) {
  switch (action.type) {
    case getLatestSessionPlanRequest.type:
      return {
        ...state,
        view: {
          loading: true,
          error: null,
        },
      };
    case getLatestSessionPlanFailure.type:
      return {
        ...state,
        view: {
          loading: false,
          error: action.payload,
        },
      };
    case getLatestSessionPlanSuccess.type:
      return {
        ...state,
        view: {
          loading: false,
          error: null,
          ...action.payload,
        },
      };
    case createSessionPlanRequest.type:
      return {
        ...state,
        view: {
          loading: true,
        },
      };
    case createSessionPlanSuccess.type:
      return {
        ...state,
        view: {
          loading: false,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
