import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Dashboard from '../../../layout/dashboard';

import { assignPhysiopalToPatientRequest } from '../../../../store/devices';
import { createPatientRequest } from '../../../../store/patients';

const mapStateToProps = (state) => ({
  patient: state.patients.view,
  serial_number: state.devices.physiopalDevice.link.serial_number,
  loading: state.patients.create.loading,
});

const mapDispatchToProps = (dispatch) => ({
  create: (values) => dispatch(createPatientRequest(values)),
  assignPhysiopalToPatient: (values) => dispatch(assignPhysiopalToPatientRequest(values)),
});

const getConfirmationModal = (serial_number, values, setModalVisible, submitForm, loading) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                You are about to link a Physiopal device with a patient account
              </h3>
              <dl className="py-5">
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.name}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Date of birth
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {values.date_of_birth}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Physiopal serial number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {serial_number}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="mt-2 sm:mt-3">
            <button onClick={() => submitForm()} type="button" disabled={loading} className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
              {loading &&
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              }
              Confirm
            </button>
          </div>
          <div className="mt-2 sm:mt-3">
            <button onClick={() => setModalVisible(false)} type="button" disabled={loading} className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewPatientSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  nhs_id: Yup.string()
    .required('Required'),
  hospital_id: Yup.string()
    .required('Required'),
  date_of_birth: Yup.date()
    .required('Required'),
  country: Yup.string()
    .required('Required'),
  gender: Yup.string()
    .required('Required')
    .oneOf(['male', 'female'])
});

function New(props) {
  const { patient, serial_number, loading, create, assignPhysiopalToPatient } = props;
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Dashboard title={patient.playphysio_id ? `Assign device to ${patient.name}` : 'New patient'}>
      <div className="p-4">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/patients" className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span className="sr-only">Patients</span>
                </Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <p aria-current="page" className="ml-4 text-sm font-medium text-gray-500">Find Physiopal</p>
                <div className="ml-4 flex items-center justify-center h-6 w-6 rounded-full bg-green-100">
                  <svg className="h-4 w-4 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <p className="ml-4 text-sm font-medium text-gray-500">Patient information</p>
              </div>
            </li>
          </ol>
        </nav>
        <div className="mb-4 bg-white overflow-hidden shadow rounded-lg">
          <div className="flex flex-col px-4 py-5 sm:p-6 align-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <p className="mt-4 flex-1 text-center">Using Physiopal device with serial number {serial_number}</p>
          </div>
        </div>
        <Formik
          validationSchema={NewPatientSchema}
          validateOnBlur
          initialValues={patient.playphysio_id ? (
            {
              ...patient,
            }
          ) : ({
            country: 'United Kingdom',
            gender: 'male',
          })}
          onSubmit={(values) => {
            if (patient.playphysio_id) {
              assignPhysiopalToPatient({
                playphysio_id: patient.playphysio_id,
                serial_number,
              });
            } else {
              create({
                serial_number,
                ...values,
              });
            }
          }}
        >
          {({
            initialValues,
            values,
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitForm,
            isValid,
            dirty
          }) => (
            <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
              <div className="px-4 py-5 sm:p-6">
                <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Patient information
                        </h3>
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Name
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                              <input disabled={initialValues.name} value={values.name} onChange={handleChange} onBlur={handleBlur} type="text" name="name" id="name" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            {errors.name &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.name}</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="nhs_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            NHS ID
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                              <input disabled={initialValues.nhs_id} value={values.nhs_id} onChange={handleChange} onBlur={handleBlur} type="text" name="nhs_id" id="nhs_id" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            {errors.nhs_id &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.nhs_id}</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="hospital_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Hospital ID
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                              <input disabled={initialValues.hospital_id} value={values.hospital_id} onChange={handleChange} onBlur={handleBlur} type="text" name="hospital_id" id="hospital_id" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            {errors.hospital_id &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.hospital_id}</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="gender" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Gender
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                              <select
                                id="gender"
                                name="gender"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                value={values.gender}
                                onChange={handleChange}
                              >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                            {errors.gender &&
                              <p className="mt-2 text-sm text-red-600" id="gender-error">{errors.gender}</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="date_of_birth" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Date of birth
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg relative rounded-md shadow-sm">
                              <input disabled={initialValues.date_of_birth} value={values.date_of_birth} onChange={handleChange} onBlur={handleBlur} type="date" name="date_of_birth" id="date_of_birth" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            {errors.date_of_birth &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.date_of_birth}</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                          <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Country
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="max-w-lg flex rounded-md shadow-sm">
                              <select
                                id="country"
                                name="country"
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue="Male"
                                autoComplete="country"
                                value={values.country}
                                onChange={handleChange}
                              >
                                <option>Austria</option>
                                <option>Belgium</option>
                                <option>Bulgaria</option>
                                <option>Croatia</option>
                                <option>Cyprus</option>
                                <option>Czech Republic</option>
                                <option>Denmark</option>
                                <option>Estonia</option>
                                <option>Finland</option>
                                <option>France</option>
                                <option>Germany</option>
                                <option>Greece</option>
                                <option>Hungary</option>
                                <option>Ireland</option>
                                <option>Italy</option>
                                <option>Latvia</option>
                                <option>Lithuania</option>
                                <option>Luxembourg</option>
                                <option>Malta</option>
                                <option>Netherlands</option>
                                <option>Poland</option>
                                <option>Portugal</option>
                                <option>Romania</option>
                                <option>Slovakia</option>
                                <option>Spain</option>
                                <option>Sweden</option>
                                <option>United Kingdom</option>
                                <option>Norway</option>
                                <option>Switzerland</option>
                              </select>
                            </div>
                            {errors.country &&
                              <p className="mt-2 text-sm text-red-600" id="country-error">{errors.country}</p>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex px-4 py-4 sm:px-6">
                <div className="flex-1" />
                {isValid ? (
                  <button type="submit" onClick={() => setModalVisible(true)} disabled={loading} className={`inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${loading ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'}`}>
                    {loading ? (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : (
                      <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                      </svg>
                    )}
                    {initialValues.playphysio_id ? 'Assign to patient' : 'Create patient'}
                  </button>
                ) : (
                  <button type="submit" disabled={true} className='inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-400'>
                    <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                    </svg>
                    {initialValues.playphysio_id ? 'Assign to patient' : 'Create patient'}
                  </button>
                )}
              </div>
              {modalVisible &&
                getConfirmationModal(serial_number, values, setModalVisible, submitForm, loading)
              }
            </div>
          )}
        </Formik>
      </div>
    </Dashboard >
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
