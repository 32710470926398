export async function getAppointments(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/appointments/?playphysio_id=${data.playphysio_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function createAppointment(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/appointments/?playphysio_id=${data.playphysio_id}`, {
    method: 'POST',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'appointment': {
        'datetime': (new Date(data.datetime).getTime() / 1000) + (3600 * 8),
      },
    })
  });
  return response.json();
};

export async function deleteAppointment(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/appointments/?playphysio_id=${data.playphysio_id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      datetime: data.datetime,
    })
  });
};
