import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Modal from '../../../Modal';

import Dashboard from '../../../layout/dashboard';

import { getTeamMemberRequest, deleteTeamMemberRequest } from '../../../../store/team';

const mapStateToProps = (state) => ({
  person: state.team.view,
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamMember: (id) => dispatch(getTeamMemberRequest(id)),
  deleteTeamMember: (id) => dispatch(deleteTeamMemberRequest(id)),
});

function TeamDetail(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const { getTeamMember, deleteTeamMember, person, account } = props;
  const { id } = useParams();
  useEffect(() => {
    getTeamMember(id);
  }, [id, getTeamMember]);
  return (
    <Dashboard title={person.name}>
      <div className="m-4 bg-white shadow sm:rounded-lg">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="px-4 sm:p-6">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Name
                  </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg relative flex rounded-md shadow-sm">
                    <input value={person.name} disabled={true} type="text" name="name" id="name" className="disabled:bg-gray-50 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Email
                  </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg relative flex rounded-md shadow-sm">
                    <input value={person.username} disabled={true} type="text" name="name" id="name" className="disabled:bg-gray-50 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 sm:p-4">
            <div className="flex justify-end">
              <button disabled={account.user == id} onClick={() => setModalVisible(!modalVisible)} type="button" className={`inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 ${account.user != id ? 'hover:bg-red-200' : 'cursor-not-allowed'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm`}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalVisible &&
            <Modal
              title={person.error ? 'Error' : 'Are you sure?'}
              content={person.error || `This will delete the account for ${person.name}.`}
              buttons={
                <>
                  <button onClick={() => deleteTeamMember(id)} disabled={person.error} type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm">
                    Confirm
                </button>
                  <button onClick={() => setModalVisible(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                    Cancel
                </button>
                </>
              }
            />
          }
    </Dashboard>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetail);
