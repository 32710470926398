import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Dashboard from '../../../layout/dashboard';

import PatientInfoCard from '../../../Cards/PatientInfo';
import SessionPlanCard from '../../../Cards/SessionPlan';
import AdherenceMonitoringCard from '../../../Cards/AdherenceMonitoring';
import ConsentCard from '../../../Cards/Consent';
import DevicesCard from '../../../Cards/Devices';
import RemindersCard from '../../../Cards/Reminders';
import AppointmentsCard from '../../../Cards/Appointments';
import QuestionnaireCard from '../../../Cards/Questionnaires';
import PracticeCard from '../../../Cards/Practice';

import { clearCreatedPatient } from '../../../../store/patients';

const PatientCreationConfirmation = ({ name, deviceSerial }) => (
  <div
    data-testid="patient-creation-confirmation"
    className="bg-green-400 overflow-hidden shadow rounded-lg mb-4"
  >
    <div className="px-4 py-5">
      <p>
        Patient {name} has been successfully linked to Physiopal device{' '}
        {deviceSerial}
      </p>
    </div>
    <div className="px-4 pb-5">
      <p className="font-bold">
        Please write the patients name on the packaging for the Physiopal with
        the matching serial number now!
      </p>
    </div>
  </div>
);

function Overview() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const createdPatient = useSelector((state) => state.patients.create);

  useEffect(() => {
    if (id !== createdPatient.playphysio_id) {
      dispatch(clearCreatedPatient());
    }
  }, [id, dispatch]);

  return (
    <Dashboard title="Overview">
      <div className="m-4">
        <div className="flex-1">
          {createdPatient.device_serial && (
            <PatientCreationConfirmation
              name={createdPatient.name}
              deviceSerial={createdPatient.device_serial}
            />
          )}
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:space-x-4 space-y-4 lg:space-y-0">
          <div className="lg:col-span-0 space-y-4">
            <PatientInfoCard />
            <SessionPlanCard />
            <PracticeCard />
            <RemindersCard />
          </div>
          <div className="lg:col-span-1 space-y-4">
            <AdherenceMonitoringCard />
            <AppointmentsCard />
            <DevicesCard />
            <ConsentCard />
            <QuestionnaireCard />
          </div>
        </div>
      </div>
    </Dashboard>
  );
}

export default Overview;
