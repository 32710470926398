import React from 'react';

import Dashboard from '../../layout/dashboard';

import SubscriptionInfo from '../../SubscriptionInfo';

export default function Subscription(props) {
  return (
    <Dashboard title="Subscription">
      <SubscriptionInfo />
    </Dashboard>
  )
};
