import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import {
  getAppointmentsRequest,
  getAppointmentsFailure,
  getAppointmentsSuccess,
  createAppointmentRequest,
  createAppointmentFailure,
  createAppointmentSuccess,
  deleteAppointmentRequest,
  deleteAppointmentFailure,
  deleteAppointmentSuccess
} from '../store/appointments';

import { getAppointments, createAppointment, deleteAppointment } from '../api/appointments';

const getToken = state => state.account.token;

function* getAppointmentsSaga(action) {
  const token = yield select(getToken);
  try {
    const response = yield call(getAppointments, {
      token,
      playphysio_id: action.payload,
    });
    yield put(getAppointmentsSuccess(response));
  } catch (exc) {
    console.error(exc);
  }
}

function* createAppointmentSaga(action) {
  const token = yield select(getToken);
  const response = yield call(createAppointment, {
    token,
    ...action.payload,
  });
  yield put(createAppointmentSuccess(action.payload.playphysio_id));
}

function* deleteAppointmentSaga(action) {
  const token = yield select(getToken);
  yield call(deleteAppointment, {
    token,
    ...action.payload,
  });
  yield put(deleteAppointmentSuccess(action.payload.playphysio_id));
}

export default function* appointmentsSaga() {
  yield takeEvery([getAppointmentsRequest.type, createAppointmentSuccess.type, deleteAppointmentSuccess.type], getAppointmentsSaga);
  yield takeEvery(createAppointmentRequest.type, createAppointmentSaga);
  yield takeEvery(deleteAppointmentRequest.type, deleteAppointmentSaga);
};
