import { createAction } from '@reduxjs/toolkit';

export const getSubscriptionRequest = createAction('GET_SUBSCRIPTION_REQUEST');
export const getSubscriptionFailure = createAction('GET_SUBSCRIPTION_FAILURE');
export const getSubscriptionSuccess = createAction('GET_SUBSCRIPTION_SUCCESS');

export const updateSubscriptionRequest = createAction('UPDATE_SUBSCRIPTION_REQUEST');
export const updateSubscriptionFailure = createAction('UPDATE_SUBSCRIPTION_FAILURE');
export const updateSubscriptionSuccess = createAction('UPDATE_SUBSCRIPTION_SUCCESS');

const initialState = {
  loading: true,
};

export default function subscription(state = initialState, action) {
  switch (action.type) {
    case getSubscriptionRequest.type:
      return {
        loading: true,
      };
    case getSubscriptionFailure.type:
      return {
        loading: false,
      };
    case getSubscriptionSuccess.type:
      return {
        ...action.payload,
        loading: false,
      };
    case updateSubscriptionRequest.type:
      return {
        loading: true,
      };
    case updateSubscriptionFailure.type:
      return {
        loading: false,
      };
    case updateSubscriptionSuccess.type:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
