export const getHumanReadableDate = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month;
  const day = date.getDate();
  const dayStr = day < 10 ? `0${day}` : day;
  const year = date.getFullYear();
  return `${year}-${monthStr}-${dayStr}`;
}

export const getUnixTimestamp = () => {
  const date = new Date();
  return date.getTime();
}

export const getMissingBlows = ({ total_required_blows, total_complete_blows }) => {
  return Math.max(total_required_blows - total_complete_blows, 0);
}

export const getTotalBlowAttempts = ({ total_incomplete_blows, total_complete_blows }) => {
  return total_incomplete_blows + total_complete_blows;
}

export const getQuality = ({ total_incomplete_blows, total_complete_blows }) => {
  const MIN_DENOMINATOR_VALUE = 1;
  const denominator = Math.max(total_incomplete_blows + total_complete_blows, MIN_DENOMINATOR_VALUE);
  return (total_complete_blows / denominator).toFixed(2);
}

export const getPlayphysioScore = ({ total_required_blows, total_incomplete_blows, total_complete_blows }) => {
  const DEFAULT_SCORE = '0.00';

  // Check if total_required_blows is 0 to prevent division by zero
  if (total_required_blows === 0) {
    return DEFAULT_SCORE;
  }

  const WEIGHTINGS = {
    missing: 0,
    incomplete: 10,
    complete: 100,
  };

  const denominator = total_required_blows;
  const incomplete = (total_incomplete_blows / denominator) * WEIGHTINGS.incomplete;
  const complete = (total_complete_blows / denominator) * WEIGHTINGS.complete;
  const score = incomplete + complete;
  return score.toFixed(2).toString() || DEFAULT_SCORE;
}
