import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { getPracticeBlowsRequest, getPracticeBlowsFailure, getPracticeBlowsSuccess, updateBlowDurationRequest, updateBlowDurationSuccess, updatePracticeStateRequest, updatePracticeStateSuccess } from '../store/practice';

import { getPracticeBlows, updateDuration, updateState } from '../api/practice';

const getToken = state => state.account.token;

function* getPracticeBlowsSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getPracticeBlows, {
    token,
    playphysio_id: action.payload,
  });
  yield put(getPracticeBlowsSuccess(response));
}

function* updateDurationSaga(action) {
  const token = yield select(getToken);
  const response = yield call(updateDuration, {
    token,
    playphysio_id: action.payload.playphysio_id,
    duration: action.payload.duration,
  });
  yield put(updateBlowDurationSuccess(action.payload.playphysio_id));
}

function* updatePracticeStateSaga(action) {
  const token = yield select(getToken);
  const response = yield call(updateState, {
    token,
    playphysio_id: action.payload.playphysio_id,
    state: action.payload.state ? 'on' : 'off',
  });
  yield put(updatePracticeStateSuccess(response));
}

export default function* practiceSaga() {
  yield takeEvery(getPracticeBlowsRequest.type, getPracticeBlowsSaga);
  yield takeEvery(updateBlowDurationRequest.type, updateDurationSaga);
  yield takeEvery(updatePracticeStateRequest.type, updatePracticeStateSaga);
};
