import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Dashboard from '../../../layout/dashboard';

import { createSessionPlanRequest } from '../../../../store/session_plans';

const mapStateToProps = (state) => ({
  loading: state.session_plans.view.loading,
  error: state.session_plans.view.error,
  patient: state.patients.view,
  session_plan: state.session_plans.view,
});

const mapDispatchToProps = (dispatch) => ({
  create: (values) => dispatch(createSessionPlanRequest(values)),
});

const getErrorModal = (setModalVisible) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Could not find a Physiopal device matching that serial number
                    </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Double check that the serial number you have entered matches the serial number on the Physiopal device sent to you by Playphysio.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button onClick={() => setModalVisible(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getSuccessModal = (setModalVisible) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Physiopal device found
                    </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  This Physiopal device can now be linked to a patient - we'll do that in the next step!
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <Link to="/patients/new/link" type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm">
              Link to patient
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const SessionPlanSchema = Yup.object().shape({
  therapy_device: Yup.string()
    .label('Therapy device')
    .required('Required')
    .oneOf(["Aerobika", "Acapella", "Shaker", "Pari", "Flutter"]),
  therapy_device_setting: Yup.number()
    .required('Required'),
  session_type: Yup.string()
    .label('Session type')
    .required('Required')
    .oneOf(["Structured session", "Timed session"]),
  min_session_duration: Yup.mixed().when('session_type', {
    is: 'Timed session',
    then: Yup.number().required('Required').typeError('Required'),
  }),
  min_blow_count: Yup.number()
    .required('Required'),
  min_blow_time: Yup.number()
    .required('Required'),
  min_set_count: Yup.number().min(1, 'Must be 1 or greater').required('Required'),
  min_position_count: Yup.number().min(1, 'Must be 1 or greater').required('Required'),
  min_daily_reps: Yup.number().min(1, 'Must be 1 or greater').required('Required'),
});

function New(props) {
  const { loading, error, create, patient, session_plan } = props;
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <Dashboard title={`Create a new routine for ${patient.name}`}>
      <div className="p-4">
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/patients" className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span className="sr-only">Patients</span>
                </Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link to={`/patients/${patient.playphysio_id}/overview`} aria-current="page" className="ml-4 text-sm font-medium text-gray-500">{patient.name}</Link>
              </div>
            </li>
            <li className="flex">
              <div className="flex items-center">
                <svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <p className="ml-4 text-sm font-medium text-gray-500">Create routine</p>
              </div>
            </li>
          </ol>
        </nav>
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="px-4 py-5 sm:p-6">
            <Formik
              validationSchema={SessionPlanSchema}
              initialValues={{
                session_type: 'Structured session',
                ...session_plan,
                min_blow_time: session_plan.min_blow_time || 0,
                min_blow_count: session_plan.min_blow_count || 0,
                min_set_count: session_plan.min_set_count || 0,
                min_position_count: session_plan.min_position_count || 0,
                min_daily_reps: session_plan.min_daily_reps || 0,
                min_session_duration: session_plan.min_session_duration || 0,
                min_pause_time: session_plan.min_pause_time || 0,
                min_inhalation_time: session_plan.min_inhalation_time || 0,
                treatment_interval: session_plan.treatment_interval || 0,
              }}
              onSubmit={(values) => {
                const payload = {
                  playphysio_id: patient.playphysio_id,
                  reminders: [],
                  activities: [],
                  ...values,
                };
                create(payload);
              }}
            >
              {({
                initialValues,
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                submitForm
              }) => (
                <>
                  <form className="space-y-8" onSubmit={handleSubmit}>
                    <div>
                      <div className="space-y-6 sm:space-y-5">
                        <label htmlFor="therapy_device" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Therapy device
                        </label>
                        <select value={values.therapy_device} onChange={handleChange} id="therapy_device" name="therapy_device" className="shadow-sm focus:ring-purple-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                          <option>Select a device</option>
                          <option>Aerobika</option>
                          <option>Acapella</option>
                          <option>Shaker</option>
                          <option>Pari</option>
                          <option>Flutter</option>
                        </select>
                        {errors.therapy_device &&
                          <p className="mt-2 text-sm text-red-600" id="email-error">{errors.therapy_device}</p>
                        }
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <label htmlFor="therapy_device_setting" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Device setting
                      </label>
                        <input value={values.therapy_device_setting} onChange={handleChange} type="number" name="therapy_device_setting" id="therapy_device_setting" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                        {errors.therapy_device_setting &&
                          <p className="mt-2 text-sm text-red-600" id="email-error">{errors.therapy_device_setting}</p>
                        }
                      </div>
                      <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <label htmlFor="session_type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Session type
                      </label>
                        <select value={values.session_type} onChange={handleChange} id="session_type" name="session_type" className="shadow-sm focus:ring-purple-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                          <option>Structured session</option>
                          <option>Timed session</option>
                        </select>
                        {errors.session_type &&
                          <p className="mt-2 text-sm text-red-600" id="email-error">{errors.session_type}</p>
                        }
                      </div>
                      {values.session_type === 'Timed session' &&
                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                          <label htmlFor="min_session_duration" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Required session duration (in minutes)
                        </label>
                          <input value={values.min_session_duration} onChange={handleChange} type="number" name="min_session_duration" id="min_session_duration" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                          {errors.min_session_duration &&
                            <p className="mt-2 text-sm text-red-600" id="email-error">{errors.min_session_duration}</p>
                          }
                        </div>
                      }
                      <div className="md:grid md:grid-cols-2 md:gap-6">
                        <div className="md:col-span-0">
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <label htmlFor="min_blow_count" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Required blows
                      </label>
                            <input value={values.min_blow_count} onChange={handleChange} type="number" name="min_blow_count" id="min_blow_count" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            {errors.min_blow_count &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.min_blow_count}</p>
                            }
                          </div>
                          {values.session_type === 'Structured session' &&
                            <>
                              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                <label htmlFor="min_set_count" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                  Required sets of blows
                          </label>
                                <input value={values.min_set_count} onChange={handleChange} type="number" name="min_set_count" id="min_set_count" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                                {errors.min_set_count &&
                                  <p className="mt-2 text-sm text-red-600" id="email-error">{errors.min_set_count}</p>
                                }
                              </div>
                              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                <label htmlFor="min_position_count" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                  Required positions for each set of blows
                        </label>
                                <input value={values.min_position_count} onChange={handleChange} type="number" name="min_position_count" id="min_position_count" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                                {errors.min_position_count &&
                                  <p className="mt-2 text-sm text-red-600" id="email-error">{errors.min_position_count}</p>
                                }
                              </div>
                            </>
                          }
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <label htmlFor="min_daily_reps" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Required daily repetitions
                      </label>
                            <input value={values.min_daily_reps} onChange={handleChange} type="number" name="min_daily_reps" id="min_daily_reps" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            {errors.min_daily_reps &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.min_daily_reps}</p>
                            }
                          </div>
                        </div>
                        <div className="md:col-span-1">
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <label htmlFor="min_blow_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Required blow duration (in milliseconds e.g. 1000 would be 1 second duration)
                      </label>
                            <input value={values.min_blow_time} onChange={handleChange} type="number" name="min_blow_time" id="min_blow_time" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                            {errors.min_blow_time &&
                              <p className="mt-2 text-sm text-red-600" id="email-error">{errors.min_blow_time}</p>
                            }
                          </div>
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <label htmlFor="measure_inhalations" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Inhalation guidance
                            </label>
                            <button name="measure_inhalations" onClick={() => setFieldValue('measure_inhalations', !values.measure_inhalations)} type="button" aria-pressed="false" className={`${values.measure_inhalations ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}>
                              <span className="sr-only">Use setting</span>
                              <span aria-hidden="true" className={`${values.measure_inhalations ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}></span>
                            </button>
                          </div>
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <label htmlFor="min_inhalation_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Recommended Inhalation duration
                            </label>
                            <input value={values.min_inhalation_time} onChange={handleChange} type="number" name="min_inhalation_time" id="min_inhalation_time" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                          </div>
                          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <label htmlFor="min_pause_time" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Recommended pause duration between inhale and exhale
                            </label>
                            <input value={values.min_pause_time} onChange={handleChange} type="number" name="min_pause_time" id="min_pause_time" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                      <label htmlFor="treatment_interval" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Time required between treatment sessions (in minutes)
                      </label>
                      <input value={values.treatment_interval} onChange={handleChange} type="number" name="treatment_interval" id="treatment_interval" className="flex-1 focus:ring-purple-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300" />
                    </div>
                    <div className="pt-5">
                      <div className="flex justify-end">
                        {loading ? (
                          <button type="submit" className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${loading ? 'bg-gray-400' : 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'}`}>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Saving...
                          </button>
                        ) : (
                          <button type="submit" className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {modalVisible &&
        error && getErrorModal(setModalVisible)
      }
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(New);
