import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { UsersIcon, UserGroupIcon } from '@heroicons/react/outline';

import Dashboard from '../../layout/dashboard';
import Loading from '../../Loading';

import { getPatients } from '../../../store/patients';
import { getTeamRequest } from '../../../store/team';

const mapStateToProps = (state) => ({
  username: state.account.username,
  user_type: state.account.user_type,
  subscription_name: state.account.subscription_name,
  patients: state.patients,
  team: state.team.list,
});

const mapDispatchToProps = dispatch => ({
  getPatients: () => dispatch(getPatients()),
  getTeam: () => dispatch(getTeamRequest()),
});

function Home(props) {
  const { username, user_type, subscription_name, getPatients, patients, team, getTeam } = props;
  useEffect(() => {
    getPatients();
    getTeam();
  }, [])
  return (
    <Dashboard title="Reporting">
      <div className="p-4">
        {!username ? <Loading /> : (
          <>
          <dl className="grid grid-cols-1 gap-5 items-center sm:grid-cols-2 lg:grid-cols-3">
            <div
              className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute bg-playphysio rounded-md p-3">
                  <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-500 truncate">Patients</p>
              </dt>
              <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                {patients.loading ? <Loading /> : (
                  <p className="text-2xl font-semibold text-gray-900">{patients.list.length} patients</p>
                )}
                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <Link to="/reporting/patients" className="font-medium text-indigo-600 hover:text-indigo-500">
                      <button type="button" className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-playphysio hover:ring-2 hover:ring-offset-2 hover:ring-purple-500">
                        Export patient data
                      </button>
                    </Link>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </>
        )}
      </div>
    </Dashboard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
