export async function getSurveys(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/patients/${data.playphysio_id}/surveys/`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export async function getSurvey(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/surveys/${data.survey_uuid}/`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  const response_json = await response.json();
  return [response, response_json];
};

export async function researcherGetSurveys(data = {}) {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/researcher/surveys/`, {
    method: 'GET',
    headers: {
      'Authorization': `Token ${data.token}`,
      'Content-Type': 'application/json'
    },
  });
  const response_json = await response.json();
  return [response, response_json];
};
