import {
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects';

import { getConsentObjects } from '../api/consent';

import {
  getConsentObjectsRequest,
  getConsentObjectsFailure,
  getConsentObjectsSuccess
} from '../store/consent';

const getToken = state => state.account.token;

function* getConsentObjectsSaga(action) {
  const token = yield select(getToken);
  const response = yield call(getConsentObjects, {
    token,
    playphysio_id: action.payload,
  });
  yield put(getConsentObjectsSuccess(response));
}

export default function* consentSaga() {
  yield takeEvery(getConsentObjectsRequest.type, getConsentObjectsSaga);
};
