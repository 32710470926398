import { createAction } from '@reduxjs/toolkit';

import { updatePracticeStateSuccess } from '../practice';

export const getPatients = createAction('GET_PATIENTS');
export const getPatientsFailure = createAction('GET_PATIENTS_FAILURE');
export const getPatientsSuccess = createAction('GET_PATIENTS_SUCCESS');

export const getPatientRequest = createAction('GET_PATIENT');
export const getPatientFailure = createAction('GET_PATIENT_FAILURE');
export const getPatientSuccess = createAction('GET_PATIENT_SUCCESS');
export const clearPatientView = createAction('CLEAR_PATIENT_VIEW');

export const getPatientDevicesRequest = createAction('GET_PATIENT_DEVICES');
export const getPatientDevicesFailure = createAction('GET_PATIENT_DEVICES_FAILURE');
export const getPatientDevicesSuccess = createAction('GET_PATIENT_DEVICES_SUCCESS');

export const pauseDeviceRequest = createAction('PAUSE_DEVICE_REQUEST');
export const pauseDeviceRequestFailure = createAction('PAUSE_DEVICE_REQUEST_FAILURE');
export const pauseDeviceRequestSuccess = createAction('PAUSE_DEVICE_REQUEST_SUCCESS');

export const updatePatientRequest = createAction('UPDATE_PATIENT');
export const updatePatientFailure = createAction('UPDATE_PATIENT_FAILURE');
export const updatePatientSuccess = createAction('UPDATE_PATIENT_SUCCESS');

export const createPatientRequest = createAction('CREATE_PATIENT');
export const createPatientFailure = createAction('CREATE_PATIENT_FAILURE');
export const createPatientSuccess = createAction('CREATE_PATIENT_SUCCESS');
export const clearCreatedPatient = createAction('CLEAR_CREATED_PATIENT');

export const getSurveysSuccess = createAction('GET_SURVEYS_SUCCESS');

const initialState = {
  view: {
    loading: false,
    devices: [],
    surveys: [],
  },
  create: {
    loading: false,
  },
  pagination: {
    previous: null,
    next: null,
  },
  list: {
    loading: false,
    items: [],
  },
  loading: false,
};

export default function patients(state = initialState, action) {
  switch (action.type) {
    case getPatientRequest.type:
      return {
        ...state,
        view: {
          ...initialState.view,
          loading: true,
        },
      };
    case getPatientSuccess.type:
      return {
        ...state,
        view: {
          ...state.view,
          loading: false,
          devices: [
            ...state.view.devices,
          ],
          ...action.payload,
        },
      };
    case clearPatientView.type: {
      return {
        ...state,
        view: {
          ...initialState.view,
        },
      };
    }
    case updatePatientRequest.type:
      return {
        ...state,
        view: {
          ...state.view,
          loading: true,
        },
      };
    case updatePatientSuccess.type:
      return {
        ...state,
        view: {
          ...state.view,
          loading: false,
          devices: [],
          ...action.payload,
        },
      };
    case getPatients.type:
      return {
        ...state,
        list: {
          ...initialState.list,
          loading: true,
        }
      };
    case getPatientsFailure.type:
      return {
        ...state,
        list: {
          ...initialState.list,
        },
      };
    case getPatientsSuccess.type:
      return {
        ...state,
        pagination: {
          previous: action.payload.previous,
          next: action.payload.next,
        },
        list: {
          loading: false,
          items: action.payload.results,
        },
      };
    case getPatientDevicesRequest.type:
      return {
        ...state,
        view: {
          ...state.view,
          devices: [],
        }
      };
    case getPatientDevicesSuccess.type:
      return {
        ...state,
        view: {
          ...state.view,
          devices: action.payload,
        },
      };
    case getSurveysSuccess.type:
      return {
        ...state,
        view: {
          ...state.view,
          surveys: action.payload,
        },
      }
    case createPatientRequest.type:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case createPatientSuccess.type:
      return {
        ...state,
        create: {
          loading: false,
          ...action.payload,
        },
      };
    case clearCreatedPatient.type:
      return {
        ...state,
        create: {
          loading: false,
        },
      };
    case updatePracticeStateSuccess.type:
      return {
        ...state,
        view: {
          ...state.view,
          practice: action.payload.practice,
        },
      };
    default:
      return state;
  }
};
