import React, { useState } from 'react';

import './styles.css';

import Loading from '../Loading';
import Sidebar from './sidebar';

export default function Dashboard(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="h-screen flex overflow-hidden bg-white bg-pattern">
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="bg-white border-b border-gray-200 p-4 flex items-center justify-between">
          <button onClick={() => setIsOpen(!isOpen)} className="mr-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden">
            <span className="sr-only">Open sidebar</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
            </svg>
          </button>
          <div className="flex-1 min-w-0">
            {props.title ? (
              <h1 data-cy="dashboard-title" data-testid="title" className="text-lg font-medium leading-6 text-gray-900 sm:truncate">{props.title}</h1>
            ) : <Loading />}
          </div>
          <div className="flex md:mt-0 md:ml-4">
            {props.buttons}
          </div>
        </div>
        <main className="flex-1 relative overflow-x-hidden overflow-y-auto focus:outline-none" tabIndex="0">
          {props.children}
        </main>
      </div >
    </div >
  );
};
