import { createAction } from '@reduxjs/toolkit';

import { endOfDay, substractDaysFromToday } from '../../components/pages/Patients/Graphs/utils';

export const getReportRequest = createAction('GET_REPORT_REQUEST');
export const getReportFailure = createAction('GET_REPORT_FAILURE');
export const getReportSuccess = createAction('GET_REPORT_SUCCESS');

export const setFromDate = createAction('SET_FROM_DATE');
export const setToDate = createAction('SET_TO_DATE');
export const resetDates = createAction('RESET_DATES');

const initialState = {
  loading: false,
  error: null,
  data: [],
  from: substractDaysFromToday(30).toISOString().split('T')[0],
  to: endOfDay(new Date()).toISOString().split('T')[0],
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case getReportRequest.type:
      return {
        ...state,
        ...initialState.data,
        error: null,
        loading: true,
      };
    case getReportFailure.type:
      return {
        ...initialState,
        error: 'Failed to load',
      };
    case getReportSuccess.type:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case setFromDate.type:
      return {
        ...state,
        from: action.payload,
      };
    case setToDate.type:
      return {
        ...state,
        to: action.payload,
      };
    case resetDates.type:
      return {
        ...state,
        from: initialState.from,
        to:initialState.to,
      };
    default:
      return state;
  }
};
