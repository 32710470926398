import {
    getPlayphysioScore
} from '../../../../utils/adherence';

export function isToday(d) {
    const date = new Date(d);
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

export function endOfDay(d) {
    const date = new Date(d);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
}

export function startOfDay(d) {
    const date = new Date(d);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function substractDaysFromToday(days) {
    var result = new Date();
    result.setDate(result.getDate() - days);
    return startOfDay(result)
}

function sumOfArray(array) {
    return array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
}

export function averageOfArray(array) {
    return sumOfArray(array) / array.length;
}

export function dateToShortString(d) {
    const date = new Date(d);
    const month = date.getMonth() + 1;
    const monthStr = month < 10 ? `0${month}` : month;
    return `${date.getDate()}/${monthStr}/${date.getFullYear()}`;
}

export function mergeDaysForAdherence(data) {
    var result = {};
    for (const item of data) {
        const start_of_day = startOfDay(item.datetime * 1000);
        if (!result[start_of_day]) {
            result[start_of_day] = [];
        }
        result[start_of_day].push(
            Math.round(Math.min((item.total_complete_blows / Math.max(item.total_required_blows, 1)) * 100, 100))
        );
    }
    for (const [key, value] of Object.entries(result)) {
        result[key] = averageOfArray(value);
    }
    return result;
}

export function mergeDaysForPlayphysioScore(data) {
    var result = {};
    for (const item of data) {
        const start_of_day = startOfDay(item.datetime * 1000);
        if (!result[start_of_day]) {
            result[start_of_day] = [];
        }
        result[start_of_day].push(getPlayphysioScore(item));
    }
    for (const [key, value] of Object.entries(result)) {
        result[key] = averageOfArray(value);
    }
    return result;
}

export function mergeDaysForSessionDuration(data) {
    var result = {};
    for (const item of data) {
        const start_of_day = startOfDay(item.datetime * 1000);
        if (!result[start_of_day]) {
            result[start_of_day] = [];
        }
        if (item.missed) {
            result[start_of_day].push(0);
        } else {
            result[start_of_day].push((item.session_end - item.session_start) / 60);
        }
    }
    return result;
}

function epochToTwentyFourHour(epoch) {
    const date = new Date(epoch * 1000);
    return `${date.getHours()}.${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
}

export function mergeDaysForSessionStartEnd(data) {
    var result = {};
    for (const item of data) {
        const start_of_day = startOfDay(item.datetime * 1000);
        if (!result[start_of_day]) {
            result[start_of_day] = [];
        }
        if (item.missed) {
            result[start_of_day].push({'start': -1, 'end': -1});
        } else {
            result[start_of_day].push({'start': epochToTwentyFourHour(item.session_start), 'end': epochToTwentyFourHour(item.session_end)});
        }
    }
    return result;
}

export function mergeDaysForCompleteIncompletePrescribed(data) {
    var result = {};
    for (const item of data) {
        const start_of_day = startOfDay(item.datetime * 1000);
        if (!result[start_of_day]) {
            result[start_of_day] = {
                'complete': 0,
                'incomplete': 0,
                'prescribed': 0,
            };
        }
        if (item.missed) {
            result[start_of_day] = {
                'complete': result[start_of_day].complete + 0,
                'incomplete': result[start_of_day].incomplete + 0,
                'prescribed': item.min_daily_reps * item.total_required_blows,
            }
        } else {
            result[start_of_day] = {
                'complete': result[start_of_day].complete + item.total_complete_blows,
                'incomplete': result[start_of_day].incomplete + item.total_incomplete_blows,
                'prescribed': item.min_daily_reps * item.total_required_blows,
            }
        }
    }
    return result;
}

export function minutesToMinutesAndSeconds(minutes) {
    const minutes_ = Math.floor(minutes);
    const seconds = Math.floor((minutes - minutes_) * 60);
    return `${minutes_}m ${seconds}s`;
}

export function secondsToHumanReadableMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const seconds_left = Math.round(seconds % 60, 2);
    return `${minutes}m ${seconds_left}s`;
}

export function generateRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}