import { createAction } from '@reduxjs/toolkit';

export const getRemindersRequest = createAction('GET_REMINDERS_REQUEST');
export const getRemindersFailure = createAction('GET_REMINDERS_FAILURE');
export const getRemindersSuccess = createAction('GET_REMINDERS_SUCCESS');

export const createReminderRequest = createAction('CREATE_REMINDER_REQUEST');
export const createReminderFailure = createAction('CREATE_REMINDER_FAILURE');
export const createReminderSuccess = createAction('CREATE_REMINDER_SUCCESS');

export const deleteReminderRequest = createAction('DELETE_REMINDER_REQUEST');
export const deleteReminderFailure = createAction('DELETE_REMINDER_FAILURE');
export const deleteReminderSuccess = createAction('DELETE_REMINDER_SUCCESS');

const initialState = {
  reminders: [],
  loading: false,
};

export default function reminders(state = initialState, action) {
  switch (action.type) {
    case getRemindersRequest.type:
      return {
        reminders: [],
        loading: true,
      };
    case getRemindersFailure.type:
      return {
        ...state,
        loading: false,
      };
    case getRemindersSuccess.type:
      return {
        ...state,
        reminders: action.payload,
        loading: false,
      };
    case createReminderSuccess.type:
      return {
        reminders: [
          ...state.reminders,
          ...action.payload
        ],
        loading: false,
      };
    default:
      return state;
  }
};
