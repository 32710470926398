export async function getReport(data = {}) {
    let url = `${process.env.REACT_APP_BASE_URL}/session_reports/?playphysio_id=${data.playphysio_id}`;
    if (data.from != "") {
        url = url + `&since=${new Date(data.from).getTime() / 1000}`;
    }
    if (data.to != "") {
        url = url + `&to=${new Date(data.to).getTime() / 1000}`;
    }
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${data.token}`,
            'Content-Type': 'application/json'
        },
    });
    return response.json();
};
